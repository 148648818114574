/* eslint-disable */
/*
 * Please review the i18n documentation located in users/src/plugins/i18n/es.js
 */

 export default {
    "payment-process": {
        "loading-text": {
            "process-payment": "Your payment is being processed.",
            "minutes-message": "This may take a few minutes..."
        }
    },
    "general": {
        "changePaymentMethod": "Change payment method",
        "help": "Help",
        "loading": "Loading...",
        "goToPagandoCheck": "Go to Pagando Check",
        "closeSession": "Sign Out",
        "actions": {
            "add": "Add",
            "change": "Change",
            "success": {
                "title": "Success",
                "text": "The action has been executed successfully."
            },
            "back": {
                "title": "Back"
            },
            "continue": {
                "title": "Continue"
            },
            "cancel": {
                "title": "Cancel"
            },
            "confirm": {
                "title": "Confirm"
            },
            "error": {
                "title": "Error",
                "text": "There was a problem executing the action"
            },
        },
        "clickable-link": {
            "mas-servicios": "See more services →",
        },
        "validations": {
            "required": "This field is required",
            "minLength": "{min} characters minimum",
            "maxLength": "{max} characters maximum",
            "numeric": "Numeric values only",
            "expirationDateValidation": "Invalid format",
            "cardNumberValid": "The number is invalid",
            "cvvValid": "The cvv is invalid",
            "expirationValid": "The expiration date is invalid",
            "additionalNotesRequired": "Required",
            "email": "Enter a valid email address."
        }
    },
    "sign-in": {
        "login-incorrect-data": "Your username or password is incorrect. Please check them and try again." ,
        "login-blocked": "You have exceeded the number of attempts to login. Please try again in 30 minutes.",
    },
    "externalPayment": {
        "baseExternal": {
            "errors" : {
                "get-info" : "An error occurred trying to load the payment order.."
            }
        },
        "login": {
            "warnings" : {
                "test-social": "Function not available in test mode",
                "no-test-email": "The email does not match any of the test",
            }
        },
        "payment": {
            "errors" : {
                "payment": "There was an error trying to process the payment, please try again later, or with a different card",
                "api-expired": "The access key has expired",
                "no-user": "There is no user for the given id",
                "email": "the email is wrong",
                "ommited-user-data": "There is an error in the format of the contact data",
                "user-not-belong-to-organization": "Unable to associate user with organization",
                "parameter-limit": "Parameter limit exceeded",
                "parameter-limit-size": "Number of parameters exceeded",
                "authorization-header": "Authorization not found",
                "ip": "Valid IP is required",
                "credentials": "The credentials provided are invalid",
                "card-invalid": "The card provided is invalid, try again later or use a different card",
                "security-code": "The security number of the card provided is invalid, try again later or use a different card",
                "card-owner": "The name of the owner of the card does not match the user",
                "card-assign": "Unable to assign card to user",
                "card-info": "The card information is not valid",
                "card-address": "Missing card address information",
                "payment-settings": "No payment settings found, contact administrator",
                "user-method": "The payment method is not associated with the user",
                "amount": "The amount to be paid has not been specified",
                "declined": "Payment was declined, please try again later or use a different card",
                "anti-fraud": "No anti fraud method found",
                "anti-fraud-token": "Invalid anti fraud token, contact administrator",
                "fraud": "The transaction was declined due to possible fraud, please try again later or use a different card",
                "route": "The configuration path for the checkout was not defined, contact the administrator",
                "order": "There is no order for the provided id",
                "default": "An unexpected error occurred, please try again later",
                "card": "The payment method could not be registered, please try again 9 later, or with a different card",
                "user": "User validation failed, please try again",
                "missing-card-owner": "Cardholder field is missing or incorrect",
                "missing-card-number": "The Card Number field is missing or incorrect",
                "missing-expiration-date": "The Expiration Date field is missing or incorrect",
                "missing-cvv": "The CVV field is missing or incorrect",
                "missing-country": "Country field missing",
                "missing-birthday": "Missing to select the field Date of birth",
                "missing-street": "Address field missing",
                "missing-postal-code": "Missing to enter the ZIP code field",
                "missing-ext-no": "The Outside Number field is missing or incorrect",
                "missing-int-no": "The Internal Number field is incorrect",
                "missing-district": "Missing to enter the Colonia field",
                "missing-state": "The State field is missing",
                "missing-city": "City field missing",
                "missing-email": "The Email field is missing or incorrect",
                "missing-phone": "Phone field is missing or incorrect",
                "no-users-test": "It is necessary to generate test users and perform tests with them before proceeding to make payments",
                "error-delete": "Unable to remove card"
            }
        }
    },
    "welcome": {
        "title": "Pay easy with Pagando Check",
        "subtitle": "By making your payments using Pagando Check, you have <strong>total protection of your personal data and your card data.</strong>",
        "payCreditDebit": {
            "title": "Pay with debit or credit card",
            "subtitle": "Make your payment with the security of Pagando, using a debit, credit or prepaid card."
        },
        "payPagandoAccount": {
            "title": "Pay with Pagando account",
            "subtitle": "Sign in to your Pagando account and make your payment securely with one of your accounts."
        },
        "payTransfer": {
            "title": "Pay with transfer",
            "subtitle": "We will provide you with a CLABE and a reference so that you can make a transfer from the bank of your choice."
        },
        "goBack": "Return to the store",
        "login": {
            "info": "Open an account to suit you without going to a branch. Make your <strong>purchases and payments for services online</strong> or at your favorite businesses with your <strong>card Mastercard.</strong>",
            "title": "Pay with Pagando",
            "subtitle": "Enter your data to sign in to your Pagando account and enjoy the benefits of your account and protection of your data.",
            "email": {
                "title": "Email",
                "placeholder": "Enter your email"
            },
            "password": {
                "title": "Password",
                "placeholder": "Enter your password"
            },
            "button": "Sign in",
            "forgotUser": "Forgot your username?",
            "forgotPass": "Did you forget your password?",
            "noRegister": "Still not registered?",
            "createAccount": "Create an account"
        }
    },
    "businessInfo": {
        "soldBy": "Sold by:",
        "phone": "Telephone:",
        "email": "Email:",
        "payDetails": "Detail of your payment",
        "contact": "Contact:",
        "total": "TOTAL"
    },
    "locale": {
        "es": "Spanish",
        "en": "English"
    },
    "footer": {
        "text": "Pagando Copyright 2021, a registered trademark of"
    },
    "payCreditDebit": {
        "step1": {
            "title": "STEP 01",
            "subtitle": "Personal information",
            "email": {
                "title": "Email",
                "placeholder": "Enter your email address",
                "errorFormat": "The format should be as follows: mail@mail.com"
            },
            "birthdate": {
                "title": "Date of birth",
                "placeholder": "DD / MM / AAAA",
            },
            "phone": {
                "title": "Phone number",
            },
        },
        "step2": {
            "title": "STEP 02",
            "subtitle": "Address",
            "address": {
                "title": "Address",
                "placeholder": "Enter the street or avenue"
            },
            "country": {
                "title": "Country",
                "placeholder": "Select the country"
            },
            "state": {
                "title": "State",
                "placeholder": "Enter the state"
            },
            "city": {
                "title": "City",
                "placeholder": "Enter the city"
            },
            "district": {
                "title": "District",
                "placeholder": "Enter the district"
            },
            "noExt": {
                "title": "Exterior number",
                "placeholder": "0",
                "error": "Value must be numeric"
            },
            "noInt": {
                "title": "Interior number",
                "placeholder": "0",
                "error": "Value must be numeric"
            },
            "zipCode": {
                "title": "Zip Code",
                "placeholder": "Enter zip code"
            }
        },
        "step3": {
            "title": "STEP 03",
            "subtitle": "Payment method",
            "cardsAccepted": "Cards accepted",
            "cardName": {
                "title": "Cardholder Name",
                "placeholder": "Fullname",
                "error": "Cardholder field format error"
            },
            "cardNumber": {
                "title": "Card number"
            },
            "expDate": {
                "title": "Due date"
            },
            "cvv": {
                "title": "CVV/CVC",
                "placeholder": "Security code",
                "error": "Value must be numeric"
            },
            "searchPromos": "Looking for available promotions...",
            "selectPromo": "Select a promotion",
            "availablePromoTitle": "Available promotions",
            "totalToPay": "Total to pay",
            "availablePromos": "What are the promotions and available banks that participate in this payment method?",
            "frequentlyQuestions": "Go to frequently questions",
            "noPromo": "No promotions available.",
            "promoMessage": "This purchase has promotions to make the payment",
            "promoMessage2": "More information about the promotions.",
            "selectedPromo": "Selected promotion",
            "typePromo": {
                "FIX_PAY": "Fixed payments",
                "MSI": "Months without interest"
            },
            "paysOf": "payments of"
        },
        "goBackPaymentMethods": "Back to payment options",
        "cancelPayment": {
            "title": "Do you want to cancel your payment?",
            "subtitle": "When canceling your payment, for security the data you have entered will not be saved. You will return to the original website and you will have to enter the data again to pay.",
            "button": "Cancel Payment"
        }
    },
    "payPagandoAccount": {
        "paymentMethods": "Payment Methods",
        "pagandoAccount": "Here you will find your <strong>Pagando accounts</strong>, select which account you want to make the payment with.",
        "loading-cards": "Loading payment methods...",
        "noAccount": "No. account:",
        "availableBalance": "Available balance",
        "paymentDetail": "Detail of your payment",
        "goBackPaymentMethods": "Back to payment options",
        "cancelPayment": {
            "title": "Do you want to cancel your payment?",
            "subtitle": "When you cancel your payment, you will automatically be logged out of your Paying for Security account. At the end of the process, you will return to the original website.",
            "button": "Cancel Payment"
        },
        "confirmationCode": {
            "title": "Enter confirmation code",
            "subtitle": "We have sent an SMS message with your code to the mobile number",
            "error": "The code you entered is not valid. Try again.",
            "message": "The SMS may take a few minutes to arrive, if you have not received it you can press the following link:"
        },
        "nip": {
            "title": "Enter the security PIN of your Pagando account",
            "subtitle": "This helps us verify your identity and confirm the security of your account.",
            "validate": "Validating PIN...",
            "error": "The PIN you have entered is incorrect. Try again."
        },
        "resendCode": "REENVIAR CÓDIGO",
        "resendCodeIn": "REENVIAR CÓDIGO EN:"
    },
    "cardDetailPayment": {
        "total": "Total",
        "pointsPagando": "Use points balance Pagando available",
        "points": "Points:",
        "valuePoint": "1 point =",
        "totalToPay": "Total to pay",
        "termsConditions1": "I accept the",
        "termsConditions2": "Terms and Conditions",
        "termsConditions3": "and the",
        "termsConditions4": "Notice of Privacy",
        "termsConditions5": "of Pagando to make this Payment.",
        "doPayment": "Make payment",
        "infoPayment": "Your payment information will be encrypted and secure."
    },
    "paymentStatus": {
        "success": {
            "title": "Payment made successfully!",
            "message": "Your payment has been made successfully. Below is a summary of your payment.",
            "phoneBusiness": "Business phone:",
            "emailBusiness": "Business mail:",
            "date": "Date and Time:",
            "folio": "Folio:",
            "totalPay": "Total paid:"
        },
        "error": {
            "tryAgain": "Try again",
            "message1": "Contact the organization with the following data for more information: {phone} - {email}",
            "title1": "The payment link is not available",
            "title2": "The payment link is not activated",
            "title3": "The payment link is not yet available",
            "message2": "It will be available until {date}, contact the organization with the following data for more information: {phone} - {email}",
            "title4": "The payment link is expired",
            "title5": "A payment has already been made with this payment link",
            "title6": "An error occurred while processing payment link",
            "title7": "Something went wrong!",
            "message3": "Sorry, we were unable to process your payment. Please try again."
        }
    }
 }
