<template>
    <div class="form-group read-only" v-bind:class="clazz">
        <label :class="titleClazz"> {{title}} </label>
        <p :class="textClazz">
            {{text}}
            <slot></slot>
        </p>
    </div>
</template>

<script>
    export default {
        name: 'ReadOnlyInfo',
        props: {
            clazz: String,
            title: String,
            text: String,
            titleClazz: String,
            textClazz: String
        }
    }
</script>
