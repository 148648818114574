export default {
    methods: {
        goBack(){
            if(document.referrer){
                this.$router.back();
            } else {
                this.$router.push('/landing');
            }
        }
    }
}
