<template>
    <div
        :id="id"
        class="basic-select"
        :class="`${clazz} ${errorClass}`"
    >
        <slot></slot>

        <div
            class="input-label"
            v-bind:class="{ 'disabled': disabled }"
        >
            <label
                class=""
                :for="labelFor"
            >{{label}} <span v-if="required">*</span></label>
            <div
                v-if="help"
                :id="idHelpTooltip"
                class="help-info"
            >
                {{helpText}} <i
                    aria-hidden="true"
                    :class="helpIcon"
                ></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i
                aria-hidden="true"
                v-bind:class="warningIcon || warningIconComputed"
            ></i>
            {{warningText || warningTextComputed}}
        </small>
    </div>
</template>

<script>
export default {
    name: 'BasicSelect',
    props: {
        id: String,
        clazz: String,
        required: { type: Boolean, default: false },
        label: String,
        labelFor: String,
        help: { type: Boolean, default: false },
        idHelpTooltip: String,
        helpText: String,
        helpIcon: String,
        warningText: String,
        warningIcon: String,
        disabled: { type: Boolean, default: false },
        validations: {},
        validationsNamespace: {
            type: String,
            default: "general.validations"
        },
    },
    computed: {
        errorClass () {
            return this.validations && this.validations.$error ? 'has-error' : '';
        },
        warningTextComputed () {
            if (this.validations) {
                const errorKey = Object
                    .keys(this.validations)
                    .find(k => Object.hasOwn(this.validations, k) && k.indexOf('$') === -1 && !this.validations[k]);
                if (errorKey) {
                    const params = this.validations.$params[errorKey];
                    return this.$t(`${this.validationsNamespace}${this.validationsNamespace ? '.' : ''}${errorKey}`, params);
                }
                return null;
            }
            return null;
        },
        warningIconComputed () {
            if (this.validations) {
                const errorKey = Object
                    .keys(this.validations)
                    .find(k => Object.hasOwn(this.validations, k) && k.indexOf('$') === -1 && !this.validations[k]);
                return errorKey ? 'banana banana-warning-circle1' : null;
            }
            return null;

        }
    }
}
</script>
