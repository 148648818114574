<template>
    <div class="payment-status" :class="[error ? 'error-text': error]">
        <img v-if="success" class="img-fluid" src="@/assets/images/Illustrations/illustration-payment-success.svg" width="284" height="280" alt="Pago exitoso" />
        <img v-if="error" class="img-fluid" src="@/assets/images/Illustrations/illustration-payment-error.svg" width="284" height="280" alt="Pago con error" />
        <img v-if="validation" class="img-fluid" src="@/assets/images/Illustrations/illustration-process.svg" width="300" height="280" alt="Validacion exitosa" />
        <img v-if="loading" class="img-fluid" src="@/assets/images/Illustrations/illustration-process.svg" width="284" height="280" alt="Cargando..." />
        <h3 :class="titleClass">{{title}}</h3>
        <p>{{text}} <slot></slot> </p>

        <div v-if="success" class="transaction-info">
            <div class="item">
                <div class="description">
                    {{$t('paymentStatus.success.phoneBusiness')}}
                </div>
                <div class="info">
                    {{phone}}
                </div>
            </div>
            <div class="item">
                <div class="description">
                    {{$t('paymentStatus.success.emailBusiness')}}
                </div>
                <div class="info">
                    {{mail}}
                </div>
            </div>
            <div class="item">
                <div class="description">
                    {{$t('paymentStatus.success.date')}}
                </div>
                <div class="info">
                    {{date}}
                </div>
            </div>
            <div class="item">
                <div class="description">
                    {{$t('paymentStatus.success.folio')}}
                </div>
                <div class="info">
                    {{folio}}
                </div>
            </div>
            <div class="item">
                <div class="description">
                    {{$t('paymentStatus.success.totalPay')}}
                </div>
                <div class="info">
                    {{total}}
                </div>
            </div>
        </div>
        <div v-if="error && checkTokenOrder()" class="button-container">
            <router-link v-if="showButtons" to="#" class="sm button button-stroke button-primary" tabindex="">
                {{$t('general.actions.back.title')}}
            </router-link>
            <a @click="tryAgain()" class="sm button button-filled button-primary" tabindex="">
                {{$t('paymentStatus.error.tryAgain')}}
            </a>
        </div>
    </div>
</template>

<script>
    import apiExternal from '@/api/apiWeb/apiWeb.api';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import {
        mapGetters
    } from 'vuex'
    const storeModule = 'externalPayment';

    export default {
        name: 'PaymentStatusMessage',
        mixins: [
            ToastSnotify
        ],
        props: {
            titleClass: String,
            title: String,
            text: String,
            phone: String,
            mail: String,
            date: String,
            folio: String,
            total: String,
            success: {type: Boolean, default: false},
            error: {type: Boolean, default: false},
            validation: {type: Boolean, default: false},
            loading: {type: Boolean, default: false},
            showTryAgain: {type: Boolean, default: true},
            showButtons: {type: Boolean, default: false},
        },
        computed: {
            ...mapGetters(storeModule, [
                'getOrder',
                'getToken',
            ]),
        },
        methods: {
            checkTokenOrder() {
                return this.getOrder && this.getToken && this.showTryAgain;
            },
            async tryAgain() {
                const token = this.getToken;
                const response = await apiExternal.createCopyOrder({orderId: this.getOrder._id}, { headers: { Authorization: token } });

                if(!response.data.error) {
                    this.$router.push({path: `/_external-payment?orderId=${response.data.object}&token=${token}`});
                } else {
                    this.displayNotificationError('Ocurrió un error la intentarlo de nuevo.');
                }

            }
        }
    }
</script>
