<template>
    <div>
        <TopbarCustom></TopbarCustom>
        <section v-if="loading">
            <LoadingBar clazz="center-floating full-view">
            </LoadingBar>
        </section>
        <div id="main-view">
            <div class="page-container">
                <PaymentStatusMessage v-if="success"
                success
                :title="$t('paymentStatus.success.title')"
                :text="$t('paymentStatus.success.message')"
                :phone="phoneFormat(getPaymentTicket.organizationPhone)"
                :mail="getPaymentTicket.organizationEmail"
                :date="getPaymentTicket.date"
                :folio="getPaymentTicket.transactionNumber.toString()"
                :total="getPaymentTicket.total | currency"
                >
                </PaymentStatusMessage>
                <PaymentStatusMessage v-if="error && !loading"
                error :showButtons="showButtonBack"
                :showTryAgain="showTryAgain"
                :title="title"
                :text="message"
                >
                </PaymentStatusMessage>
                <PaymentStatusMessage v-if="loading"
                    loading
                    :title="title"
                    :text="message"
                >
                </PaymentStatusMessage>
                <BottomFloatingNote
                :text="$t('footer.text')"
                strong="Black Labs S.A. de C.V."
                />
            </div>
        </div>
    </div>
</template>


<script>

import ToastSnotify from '@/mixins/toastSnotify.mixin';
import paymentLinkApi from "@/api/paymentLink.api.js";
const storeModule = 'externalPayment';
import LoadingBar from '@/components/LoadingBar.vue';
import imgError from '@/assets/images/Illustrations/illustration-payment-error.svg';
import imgSuccess from '@/assets/images/Illustrations/illustration-payment-success.svg';
import { mapGetters } from 'vuex';
import moment from "moment";
import TopbarCustom from '@/components/Topbar.vue'
import PaymentStatusMessage from '@/components/PaymentStatusMessage.vue'
import BottomFloatingNote from '@/components/BottomFloatingNote.vue'
import {
  STORE as SessionStore,
  CLEAR_STORE,
} from "@/store/modules/session/session.types";

const errorMessageGeneral = 'paymentStatus.error.title6';

export default {
    name: "paymentLinkShort",
    mixins: [
        ToastSnotify
    ],
    components: {
        LoadingBar,
        TopbarCustom,
        BottomFloatingNote,
        PaymentStatusMessage
    },
    methods: {
        phoneFormat(phone) {
            const phoneWithoutFormat = phone.replace(/\D+/g, "");

            const size = phoneWithoutFormat.length;
            let phoneFinal = phoneWithoutFormat

            if(size > 10) {
                const phone1 = phoneWithoutFormat.substr(size - 10);
                phoneFinal = phone1;
            }

            return phoneFinal.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '($1) $2-$3');

        },
        _getMessagesLink(paymentLink) {
            this.message = this.$t('paymentStatus.error.message1', {phone: this.phoneFormat(paymentLink.organization.phone), email: paymentLink.organization.email});
            if(paymentLink && paymentLink.deleted.isDeleted) {
                this.title = this.$t('paymentStatus.error.title1');
            } else {
                if(paymentLink && !paymentLink.active && paymentLink.status === 'DISABLED') {
                    this.title = this.$t('paymentStatus.error.title2');
                }

                if(paymentLink && paymentLink.status === 'ACTIVE' && paymentLink.expDate && moment(paymentLink.expDate.startDate) > moment()) {
                        this.title = this.$t('paymentStatus.error.title3');
                        this.message = this.$t('paymentStatus.error.message2',
                            {date: moment(paymentLink.expDate.startDate).format('DD/MM/YYYY hh:mm a'),
                            phone: this.phoneFormat(paymentLink.organization.phone), email: paymentLink.organization.email});
                }

                if(paymentLink && paymentLink.status === 'EXPIRED') {
                    this.title = this.$t('paymentStatus.error.title4');
                }

                if(paymentLink && paymentLink.status === 'PAID') {
                    this.title = this.$t(errorMessageGeneral);
                }
            }
        },
        getMessageFromLink(paymentLink) {
            if(paymentLink) {
                this._getMessagesLink(paymentLink);
            } else {
                this.title = this.$t(errorMessageGeneral);
            }
        },
        async getOriginalLink() {
            const response = await paymentLinkApi.getFullCurrentURL({code: this.token});
            if(response.data.error) {
                this.error = true;
                this.loading = false;
                const paymentLink = response.data.object;
                this.getMessageFromLink(paymentLink);

                this.displayNotificationError(this.$t('paymentLink.errorProccess'));
            } else {
                this.orderId = response.data.object.orderId;
                this.newToken = response.data.object.token;
                this.$store.commit(`${storeModule}/SET_PAYMENT_LINK`, response.data.object.id);
                this.$router.push({path: `/_external-payment?orderId=${this.orderId}&token=${this.newToken}`});
            }
        },
        getMessages(paymentLink) {
            this.message = this.$t('paymentStatus.error.message1', {phone: this.phoneFormat(this.getOrder.organization.phone), email: this.getOrder.organization.email});
            if(!paymentLink.active && paymentLink.status === 'DISABLED') {
                this.title = this.$t('paymentStatus.error.title2');
            }
            if(paymentLink.status === 'EXPIRED') {
                this.title = this.$t('paymentStatus.error.title4');
            }

            if(paymentLink.status === 'PAID') {
                this.title = this.$t(errorMessageGeneral);
            }
        },
        getValidations() {
            if(!this.getOrder.payResponse || (!this.getOrder.configCheckout.returnLink && this.getOrder.payResponse === 'APPROVED') ) {
                this.error = true;
                this.showButtonBack = false;
                this.showTryAgain = false;
            } else {
                if(!this.response) {
                    this.error = true;
                    const paymentLink = this.getOrder.paymentLink;
                    if(paymentLink) {
                        this.getMessages(paymentLink);
                    } else {
                        this.showButtonBack = true;
                        this.showTryAgain = true;
                    }
                    // this.displayNotificationError(this.$t('paymentLink.errorProccess'));
                } else {
                    this.success = true;
                }
            }
        }
    },
    computed: {
        ...mapGetters(storeModule, [
            'getOrder', 'getPaymentTicket'
        ]),
    },
    props: {
        response: {type: Boolean}
    },
    data () {
        return {
            imgError: imgError,
            imgSuccess: imgSuccess,
            token: null,
            orderId: null,
            newToken: null,
            error: false,
            success: false,
            loading: false,
            message: null,
            title: null,
            showButtonBack: false,
            showTryAgain: true
        }
    },
    async created () {
        this.message = this.$t('paymentStatus.error.message3');
        this.title = this.$t('paymentStatus.error.title7');
        this.loading = true;
        this.token = this.$route.query.t;
        if(this.token) {
            this.$store.commit(`${SessionStore}/${CLEAR_STORE}`);
            await this.getOriginalLink();
        } else {
            this.getValidations();
        }

        this.loading = false;

    }
}
</script>
