import UxComponents from "@/pages/UxComponents.vue";
import ExternalRoutes from "@/routes/externalPayment/externalPayment.routes";
import ExternalPayment from '@/views/externalPayment/ExternalPayment.vue';
import PaymentLink from '@/views/externalPayment/PaymentLink.vue';
import PaymentLinkShort from '@/views/externalPayment/PaymentLinkShort.vue';
// import CardPayment from "@/views/UI/CardPayment.vue";
// import PagandoPayment from "@/views/UI/PagandoPayment.vue";
// import PaymentMethod from "@/views/UI/paymentMethod.vue";
// import PaymentStatus from "@/views/UI/PaymentStatus.vue";
// import TransferPayment from "@/views/UI/TransferPayment.vue";
import Vue from "vue";
import Router from "vue-router";



/**
 * OJO:
 * Cuando se agregen nuevas rutas, hay que agregarlas dentro del archivo de palabras reservadas en el archivo de palabras reservadas en el proyecto web (web/v2/reservedWords.js)
 */

Vue.use(Router);

// const externalPayment = '_external-payment';
const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        // {
        //     path: "/_external-payment",
        //     component: ExternalPayment,
        //     children: ExternalRoutes
        // },
        // UX/UI Views/Pages
        {
            path: "/uxcomponents",
            name: "uxcomponents",
            component: UxComponents
        },
        // {
        //     path: "/metodo-de-pago",
        //     name: "PaymentMethod",
        //     component: PaymentMethod
        // },
        // {
        //     path: "/pago-con-pagando",
        //     name: "PagandoPayment",
        //     component: PagandoPayment
        // },
        // {
        //     path: "/pago-con-tarjeta",
        //     name: "CardPayment",
        //     component: CardPayment
        // },
        // {
        //     path: "/pago-con-transferencia",
        //     name: "TransferPayment",
        //     component: TransferPayment
        // },
        // {
        //     path: "/estado-del-pago",
        //     name: "PaymentStatus",
        //     component: PaymentStatus
        // },
        {
            path: "/_external-payment",
            component: ExternalPayment,
            children: ExternalRoutes
        },
        {
            path: '',
            name: "paymentLinkShort",
            component: PaymentLinkShort,
            props: true
        },
        {
            path: '/payment-link',
            name: "paymentLink",
            component: PaymentLink,
            props: true
        }
    ]
});

/**
 * Devuelve el valor de la variable de entorno NODE_ENV o 'dev' como default.
 * @returns {string} environment de node actual
 */
 const getEnv = () => {
    return !process.env.NODE_ENV ? "dev" : process.env.NODE_ENV;
}

/**
 * Indica si la aplicación se encuentra en modo producción.
 * @returns {boolean} true si se encuentra en modo producción
 */
function isProd () {
    return getEnv().toLowerCase() === "production" || getEnv().toLowerCase() === "prod";
}

router.beforeEach(async (to, from, next) => {
    if(to.path.indexOf("/uxcomponents") !== -1 && isProd()){
        return next('/');
    }

    if (to.matched.some(record => record.meta.middleware)) {

        const middlewareFound = checkMiddlewares(to.matched);

        const context = {
            from,
            next,
            router,
            to,
        };

        const nextMiddleware = nextFactory(context, middlewareFound, 1);

        return middlewareFound[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

function checkMiddlewares(matched) {
    const middlewareFound = [];
    for (const i of matched) {
        if (i.meta.middleware) {
            if (Array.isArray(i.meta.middleware)) {
                for (const c of i.meta.middleware) {
                    middlewareFound.push(c);
                }
            } else {
                middlewareFound.push(i.meta.middleware)
            }
        }
    }

    return middlewareFound;
}

// router.beforeEach((to, from, next) => {
//     if(to.path.indexOf("/_external-payment/payment") !== -1 || to.path.indexOf("/_external-payment") !== -1){
//         return next();
//     }
//     return next();
// });

export default router;
