<template>
    <div id="" class="card-detail-payment" :class="clazz">
        <h2>
            {{ title }}
        </h2>
        <slot></slot>
        <div class="dasher-divider"></div>
        <div class="total">
            <div>{{$t('cardDetailPayment.total')}}</div>
            <strong class="" :class="[points ? 'c-text_content': 'c-primary']"> {{ total }} MXN </strong>
        </div>
        <div class="dasher-divider"></div>
        <div v-if="points" class="points">
            <div>
                <BasicCheckbox :index="0" :currentValue="false">
                    <div>
                    {{$t('cardDetailPayment.pointsPagando')}}
                    <br />
                    <div class="poppins-font-medium f-10 f-700">
                        {{$t('cardDetailPayment.points')}} {{ points }} / {{$t('cardDetailPayment.valuePoint')}} = ${{ valuePoints }}
                    </div>
                    </div>
                </BasicCheckbox>
            </div>
            <strong> -{{ discount }} </strong>
        </div>
        <div v-if="points" class="dasher-divider"></div>
        <div v-if="points" class="total">
            <div>{{$t('cardDetailPayment.totalToPay')}}</div>
            <strong> {{ totalWithDiscount }} MXN </strong>
        </div>
        <div v-if="points" class="dasher-divider"></div>
        <div v-if="payBtn">
            <BasicCheckbox :index="0" :currentValue="acceptTerms" @change="checkButton" clazz="m-b-20">
                <div class="f-12 c-text_info f-500">
                {{$t('cardDetailPayment.termsConditions1')}}
                <router-link
                    to="#"
                    tabindex=""
                    class="c-info text-underline hover f-700"
                    >{{$t('cardDetailPayment.termsConditions2')}}</router-link
                >
                {{$t('cardDetailPayment.termsConditions3')}}
                <router-link
                    to="#"
                    tabindex=""
                    class="c-info text-underline hover f-700"
                    >{{$t('cardDetailPayment.termsConditions4')}}</router-link
                >
                {{$t('cardDetailPayment.termsConditions5')}}
                </div>
            </BasicCheckbox>
            <button class="button button-filled button-primary w-100"
                :class="disabledButton ? 'disabled' : ''" :disabled="disabledButton" @click="doPayment()">
                {{$t('cardDetailPayment.doPayment')}}
            </button>
        </div>
        <div class="bottom-note">
            <i aria-hidden="true" class="iconsax-money-security-card"></i>
            {{$t('cardDetailPayment.infoPayment')}}
        </div>
    </div>
</template>

<script>
    import BasicCheckbox from "@/components/BasicCheckbox.vue";
    export default {
    name: "CardDetailPayment",
    components: {
        BasicCheckbox,
    },
    props: {
        title: String,
        total: String,
        discount: String,
        totalWithDiscount: String,
        points: String,
        valuePoints: String,
        clazz: String,
        payBtn: { type: Boolean, default: true },
        disabledPayment: { type: Boolean, default: true },
    },
    data(){
        return {
            acceptTerms: false
        }
    },
    computed: {
        disabledButton() {
            return this.disabledPayment || !this.acceptTerms;
        }
    },
    methods: {
        doPayment() {
            if(this.acceptTerms && !this.disabledPayment) {
                this.$emit('doPayment');
            }
        },
        checkButton(val) {
            this.acceptTerms = val.checkValue;
            if(val.checkValue) {
                this.$emit('checkButtonPayment');
            }
        }
    }
    };
</script>
