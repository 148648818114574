import hasUserSession from '@/middleware/hasUserSession';

const Welcome = () => import('@/views/externalPayment/Welcome.vue');
const SelectPayment = () => import('@/views/externalPayment/SelectPayment.vue');
const GuestPayment = () => import('@/views/externalPayment/GuestPayment.vue');


export default [
    {
        path: '',
        name: "externalWelcome",
        component: Welcome,
    },
    {
        path: 'payment',
        name: "externalSelectPayment",
        component: SelectPayment,
        meta: {
            middleware: [hasUserSession]
        },
    },
    {
        path: 'guest',
        name: "guestPayment",
        component: GuestPayment
    }
]
