import {
    SET_TOKEN,
    SET_USER,
    SET_PROFILE,
    SET_PERMISSIONS,
    SET_TOKEN_EXPIRE_DATE,
    GET_TOKEN,
    GET_USER,
    GET_PERMISSIONS,
    GET_TOKEN_EXPIRE_DATE,
    GET_IS_OWNER,
    GET_IS_ADMIN,
    GET_PROFILE_ADMIN,
    CLEAR_STORE,
    SET_CURRENT_LOCATION,
    GET_CURRENT_LOCATION
} from './session.types';
import axios from 'axios';
// import sentryManager from '@/plugins/sentry';

// Objetos
const stateGlobal = {
    token: '',
    tokenExpireDate: new Date(),
    permissions: {},
    user: null,
    profile: {},
    isOwner: false,
    organizationProfiles: [],
    isAdmin: false,
    adminProfile: "",
    currentLocation: {}
};

// Métodos para obtener valores del state. Se les llama con getters
const getters = {
    // Obtiene el Token JW
    [GET_TOKEN]: (state) => state.token,
    [GET_USER]: (state) => state.user,
    [GET_PERMISSIONS]: (state) => state.permissions,
    [GET_TOKEN_EXPIRE_DATE]: (state) => state.tokenExpireDate,
    [GET_IS_OWNER]: (state) => state.isOwner,
    [GET_IS_ADMIN]: (state) => state.isAdmin,
    [GET_PROFILE_ADMIN]: (state) => state.adminProfile,
    [GET_CURRENT_LOCATION]: (state) => state.currentLocation
};

// Métodos síntronos que afectan el state. Se llaman con commit
const mutations = {
    [SET_TOKEN]: (state, payload) => {
        state.token = payload
        axios.defaults.headers.common.Authorization = `Bearer ${payload}`;
    },
    [SET_USER]: (state, payload) => {
        localStorage.setItem('currentUser', JSON.stringify(payload));
        state.user = payload;
        // sentryManager.refreshCurrentUser(payload);
    },
    [SET_PROFILE]: (state, payload) => {state.profile = payload},
    [SET_PERMISSIONS]: (state, payload) => {state.permissions = payload},
    [SET_TOKEN_EXPIRE_DATE]: (state, payload) => {state.permissions = payload},
    [CLEAR_STORE]: (state) => {
        state.token = '';
        state.tokenExpireDate= new Date();
        state.permissions= {};
        state.user=null;
        state.profile= {};
        state.isOwner= false;
        state.organizationProfiles= [];
        state.isAdmin= false;
        state.adminProfile= "";
    },
    [SET_CURRENT_LOCATION]: (state, payload) => {
        localStorage.setItem('currentLocation', JSON.stringify(payload));
        state.currentLocation = payload
    }
};

// Métodos asíncronos que hacen cosas y luego hacen commit. Se llaman con dispatch
const actions = {
};

const namespaced = true;
export default {namespaced, state: stateGlobal, getters, actions, mutations};
