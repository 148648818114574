<template>
    <div id="" class="card-container" :class="clazz">
        <div class="title-card" :class="clazzTitle" v-if="!hideTitle">
            <h2>
                {{title}}
                <label>{{subtitle}}</label>
            </h2>
            <span class="buttons"><slot name="titleButtons"></slot></span>
        </div>
        <div class="body-card" :class="clazzBody">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardContainer',
        props: {
            title: {type: String, default: ''},
            subtitle: {type: String, default: ''},
            clazz: String,
            clazzTitle: String,
            clazzBody: String,
            hideTitle: {type: Boolean, default: false},
        }
    }
</script>
