import router from '../../router'
import userApi from '../../api/user/users.api';
import config from '@/config';
import store from '@/store/index'
import apiExternal from '@/api/apiWeb/apiWeb.api'
const storeExternal = 'externalPayment';
import { EventBus } from "@/main";

const stateGlobal = {
    currentUser: null,
    codeEmail: null,
    currentToken: null,
    userDashboard: null,
    loadingDashboardUser: true,
    showAlert: false,
    registerErrorMessage: "",
    isLoadingRegister: false,
    isLoadingLogin: false,
    currentEmail: "correo@correo.com",
    isLoadingVerification: false,
    variantAlert: "danger",
    alertMessage: "",
    loginAlertMessage: "",
    loginBlocked: false,
    loginAlert: false,
    rightSide: "STEP_ONE",
    userFound: false,
    userPhrase: "",
    userImageId: "",
    userFullName: "",
    query: null,
    isSocialMediaUser: false,
    userPhone: null,
    isExternalPayment: false,
    sessionModal: false,
    activeSessions: 1,
    token: '',
    app: '',
    expireDate: 0,
    copyUserPhone: null,
    hasNewRegisterFinish: false
};

import {
    STORE as SESSION_STORE,
    SET_USER,
    SET_TOKEN,
    SET_TOKEN_EXPIRE_DATE
} from '@/store/modules/session/session.types';

const gettersGlobal = {
    getCurrentUser: (state) => state.currentUser,
    getShowAlert: (state) => state.showAlert,
    getAlertMessage: (state) => state.alertMessage,
    getAlertVariant: (state) => state.variantAlert,
    getIsLoadingRegister: (state) => state.isLoadingRegister,
    getIsLoadingLogin: (state) => state.isLoadingLogin,
    getIsLoadingVerification: (state) => state.isLoadingVerification,
    getCurrentEmail: (state) => state.currentEmail,
    getLoginBlocked: (state) => state.loginBlocked,
    getLoginAlert: (state) => state.loginAlert,
    getLoginAlertMessage: (state) => state.loginAlertMessage,
    getRightSide: (state) => state.rightSide,
    getUserFound: (state) => state.userFound,
    getUserPhrase: (state) => state.userPhrase,
    getUserImageId: (state) => state.userImageId,
    getUserFullName: (state) => state.userFullName,
    getQuerySocialMedia: (state) => state.query,
    getIsSocialMediaUser: (state) => state.isSocialMediaUser,
    getUserPhone: (state) => state.userPhone,
    getIsExternalPayment: (state) => state.isExternalPayment,
    getSessionModal: (state) => state.sessionModal,
    getActiveSessions: (state) => state.activeSessions,
    getApp: (state) => state.app,
    getExpireDate: (state) => state.expireDate,
    getToken: (state) => state.token,
    getyCopyUserPhone: (state) => state.copyUserPhone,
    getHasNewRegisterFinish: (state) => state.hasNewRegisterFinish,
};

const _handleLoginError = function (commit, data) {
    if (data.data && data.data.user) {
        if (data.data.user.loginBlocked) {
            commit('setLoginBlocked', true)
        } else {
            commit('setLoginAlert', true)
            commit('setLoginAlertMessage', data.message)
        }
    } else {
        commit('setLoginAlertMessage', data.message)
        commit('setLoginAlert', true)
    }
}

const _navigateToNextPath = function(app, expireDate, token) {
    const now = new Date();
    const pathExpireDateISOString = localStorage.getItem('LOGIN_PATH_EXPIRE_DATE');
    let path = localStorage.getItem('LOGIN_PATH');

    if (!pathExpireDateISOString) {
        path = null;
    } else if (path) {
        const pathExpireDate = new Date(pathExpireDateISOString);
        if (now > pathExpireDate) {
            path = null;
        }
    }

    localStorage.removeItem('LOGIN_PATH');
    localStorage.removeItem('LOGIN_EXPIRE_DATE');

    if (app && config[app]) {
        const host = `${config[app].host}:${config[app].port}/${config[app].endpoint}${config[app].endpoint.length > 0 ? '/' : ''}`;
        window.location = `${host}load-session?app=${app}&path=${path}&expireDate=${expireDate}&currentToken=${token}`;
    } else if (path) {
        let pathWithoutSlash;
        if (path[0] === '/') {
            pathWithoutSlash = path.substring(1);
        } else {
            pathWithoutSlash = path;
        }
        window.location = `${config.users.host}:${config.users.port}/${config.users.endpoint}${config.users.endpoint.length > 0 ? '/' : ''}${pathWithoutSlash}`;
    } else {
        router.push({ name: "HomeLanding" })
    }
}

const _redirectLogin = function(getters, rootGetters, data) {
    if (getters.getIsExternalPayment) {
        const orderId = rootGetters[`${storeExternal}/getOrder`];
        const token = rootGetters[`${storeExternal}/getToken`]
        router.push({ name: data.route , query: {
                orderId: orderId.orderId,
                token: token
            }
        }).then(() => {
            EventBus.$emit(`${storeExternal}-finish-logging`);
        })
    } else {
        router.push('/signin');
    }
};

const actions = {
    async signUp({ dispatch, commit }, { credentials }) {
        commit('SET_LOADING_REGISTER', true);
        const response = await dispatch(`singinup/save`, {
            email: credentials.email,
            password: credentials.password
        }, { root: true });
        if (response.data.error) {
            commit(`SET_ALERT_MESSAGE`, {
                variant: "danger",
                message: response.data.message
            });
            commit('SET_LOADING_REGISTER', false);
        } else {
            const email = response.data.data.email;
            router.push({ name: "SigninRegisterSuccess", params: { email: email } })
        }
    },
    async login({ commit, getters, rootGetters }, { credentials, _session}) {
        commit('setLoginAlert', false)
        commit('SET_LOADING_LOGIN', true);
        const response = await userApi.login({ email: credentials.email, password: credentials.password });
        if (response.data) {
            if (response.data.error) {
                _handleLoginError(commit, response.data);
            } else {
                // Logged in correctly
                const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
                _session.set('jwt', response.data.data.token);
                _session.set('expireDate', expireDate);
                _session.set('permissions', response.data.data.permissions);
                _session.set('profile', response.data.data.profile);

                // Guardar los datos en local storage para facilitar la compartición de datos entre las aplicaciones
                localStorage.setItem('currentUser', JSON.stringify(response.data.data.user));
                store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.data.user);
                localStorage.setItem('currentToken', JSON.stringify(response.data.data.token));
                const validToken = response.data.data.token.replace(/"/g, "");
                store.commit(`${SESSION_STORE}/${SET_TOKEN}`, validToken);
                store.commit(`${SESSION_STORE}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
                localStorage.setItem('currentPermissions', JSON.stringify(response.data.data.permissions));
                localStorage.setItem('profile', JSON.stringify(response.data.data.profile));
                localStorage.setItem('expireDate', JSON.stringify(expireDate));
                const app = _session.get('app');
                _session.remove('app');
                commit('valuesToNextPath', {app, expireDate, token: response.data.data.token})
                const checkActiveSessions = await userApi.checkActiveSessions();
                commit('setActiveSessions', checkActiveSessions.data.object);
                if (getters.getIsExternalPayment) {
                    _redirectLogin(getters, rootGetters, {route: "externalSelectPayment"})
                } else {
                    _navigateToNextPath(app, expireDate, response.data.data.token);
                }
                commit('CLEAR_STORE');
            }
        } else {
            commit('setLoginAlert', true)
        }
        commit('SET_LOADING_LOGIN', false);
    },
    async getUserByEmail({ commit }, { email}){
        commit('SET_LOADING_LOGIN', true);
        const response = await userApi.getUserByEmail({ email: email}).catch(()=>{
            commit('setLoginAlert', true)
        });
        if(!response || response.data.error){
            commit('setLoginAlert', true)
        } else {
            commit('SET_USER_FOUND', true);
            commit('SET_USER_PHRASE', response.data.object.securityPhrase)
            commit('SET_USER_IMAGE_ID', response.data.object.securityImageId)
            const fullName = `${response.data.object.name}`;
            commit('SET_USER_FULL_NAME', fullName)
        }
        commit('SET_LOADING_LOGIN', false);
    },
    async resendVerificationEmail({ commit }, email) {
        commit('SET_LOADING_VERIFICATION', true);
        const response = await userApi.resendVerificationEmail({ email: email });
        if (response.data.error) {
            commit(`REGISTER_ERROR`, response.data.message);
            commit('SET_LOADING_VERIFICATION', false);
        } else {
            commit(`SET_ALERT_MESSAGE`, {
                variant: "success",
                message: response.data.message
            });
            commit('SET_LOADING_VERIFICATION', false);
        }
    },
    async save(hold, data) {
        try {
            return await userApi.save(data);
        } catch (err) {
            return {
                data: {
                    error: true,
                    message: err
                }
            }
        }
    },

    async loginApiCheckout({ commit, rootGetters }, { credentials, _session}) {
        commit('setLoginAlert', false);
        commit('SET_LOADING_LOGIN', true);
        const token = rootGetters[`${storeExternal}/getToken`];
        const response = await apiExternal.login({ email: credentials.email, password: credentials.password }, {Authorization: token});
        if (response.data) {
            if (response.data.error) {
                _handleLoginError(commit, response.data);
            } else {
                // Logged in correctly
                const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
                _session.set('jwt', response.data.data.token);
                _session.set('expireDate', expireDate);
                _session.set('permissions', response.data.data.permissions);
                _session.set('profile', response.data.data.profile);

                // Guardar los datos en local storage para facilitar la compartición de datos entre las aplicaciones
                localStorage.setItem('currentUser', JSON.stringify(response.data.data.user));
                store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.data.user);
                localStorage.setItem('currentToken', JSON.stringify(response.data.data.token));
                const validToken = response.data.data.token.replace(/"/g, "");
                store.commit(`${SESSION_STORE}/${SET_TOKEN}`, validToken);
                store.commit(`${SESSION_STORE}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
                localStorage.setItem('currentPermissions', JSON.stringify(response.data.data.permissions));
                localStorage.setItem('profile', JSON.stringify(response.data.data.profile));
                localStorage.setItem('expireDate', JSON.stringify(expireDate));
                _session.remove('app');
                const orderId = rootGetters[`${storeExternal}/getOrder`];
                router.push({ name: "externalSelectPayment" , query: {
                        orderId: orderId.orderId,
                        token: token
                    }
                });
                commit('CLEAR_STORE');
            }
        } else {
            commit('setLoginAlert', true)
        }
    },
};

const mutations = {
    setCurrentUser(state, payload) {
        if (payload) {
            const name = payload.displayName;
            const email = payload.email;
            const photoUrl = payload.photoURL;
            const emailVerified = payload.emailVerified;
            const uid = payload.uid;
            state.currentUser = {
                name: name,
                email: email,
                photoUrl: photoUrl,
                emailVerified: emailVerified,
                uid: uid
            };
            if (this.$vue) {
                if (this.$vue.$session.has('user')) {
                    this.$vue.$session.remove('user')
                }
                this.$vue.$session.set('user', {
                    name: name,
                    email: email,
                    photoUrl: photoUrl,
                    emailVerified: emailVerified,
                    uid: uid
                });
            }
        } else {
            state.currentUser = null;
        }
    },

    SET_DASHBOARD_USER(state, user) {
        state.userDashboard = user;
    },

    SET_ALERT_MESSAGE(state, data) {
        state.showAlert = true;
        state.alertMessage = data.message;
        state.variantAlert = data.variant
    },
    SET_LOADING_REGISTER(state, value) {
        state.isLoadingRegister = value;
    },
    SET_LOADING_LOGIN(state, value) {
        state.isLoadingLogin = value;
    },
    SET_LOADING_VERIFICATION(state, value) {
        state.isLoadingVerification = value;
    },
    SET_EMAIL_SUCCESS_REGISTER(state, email) {
        state.currentEmail = email;
    },

    SET_LOADING_DASHBOARD_USER(state, value) {
        state.loadingDashboardUser = value;
    },

    setCurrentToken(state, code) {
        state.currentToken = code;
    },

    setLoginBlocked(state, value) {
        state.loginBlocked = value
        state.isLoadingLogin = false
    },

    setLoginAlert(state, value) {
        state.loginAlert = value
        state.isLoadingLogin = false
    },
    setLoginAlertMessage(state, value) {
        state.loginAlertMessage = value
    },
    SET_RIGHT_SIDE_BODY(state, value) {
        state.rightSide = value
    },
    SET_USER_FOUND(state, value) {
        state.userFound = value
    },
    SET_USER_PHRASE(state, value) {
        state.userPhrase = value
    },
    SET_USER_IMAGE_ID(state, value) {
        state.userImageId = value
    },
    SET_USER_FULL_NAME(state, value) {
        state.userFullName = value
    },
    SET_QUERY_RED_SOCIAL(state, value){
        state.query = value;
    },
    UPDATE_QUERY_RED_SOCIAL(state, value){
        if (Object.keys(state.query).length) {
            const key = Object.keys(value)[0];
            state.query[key] = value[key];
        }
    },
    SET_IS_SOCIAL_MEDIA_USER(state, value){
        state.isSocialMediaUser = value;
    },
    SET_USER_PHONE(state, value){
        state.userPhone = value;
    },
    CLEAR_STORE(state){
        state.currentUser = null;
        state.codeEmail = null;
        state.currentToken = null;
        state.userDashboard = null;
        state.loadingDashboardUser = true;
        state.showAlert = false;
        state.registerErrorMessage = "";
        state.isLoadingRegister = false;
        state.isLoadingLogin = false;
        state.currentEmail = "correo@correo.com";
        state.isLoadingVerification = false;
        state.variantAlert = "danger";
        state.alertMessage = "";
        state.loginBlocked = false;
        state.loginAlert = false;
        state.isSocialMediaUser = false;
        state.rightSide = "EASY_PAY";
        state.userFound = false;
        state.userPhrase = "";
        state.userImageId = "";
        state.userFullName = "";
        state.sessionModal = false;
        state.activeSessions = 1;
        state.app = '';
        state.expireDate = 0;
        state.token = '';
        state.copyUserPhone = null;
        state.hasNewRegisterFinish = false;
    },
    SET_EXTERNAL_PAYMENT(state, value){
        state.isExternalPayment = value;
    },
    showSessionsModal(state, value) {
        state.sessionModal = value;
    },
    setActiveSessions(state, value) {
        state.activeSessions = value;
    },
    valuesToNextPath(state, values) {
        state.app = values.app;
        state.expireDate = values.expireDate;
        state.token = values.token;
    },
    valuesForSocialLogin(state, values){
        state.hasNewRegisterFinish = values.hasNewRegisterFinish;
        state.copyUserPhone = values.userPhone;
    }
};

export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
