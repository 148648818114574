<template>
    <div class="checkbox" v-bind:class="clazz">
        <div class="p-relative d-flex">
            <input v-bind:class="inputClass" :id="checkId" type="checkbox" v-model="checkboxValue" :disabled="disabled"
                :true-value="true" :false-value="false" value="" @change="onCheckboxChange">
            <i aria-hidden="true" class="input-helper"></i>
        </div>
        <span :class="textClass">{{text}}</span>
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'BasicCheckbox',
        data() {
            return {
                checkboxValue: this.currentValue
            }
        },
        props: {
            inputClass: String,
            checkId: String,
            text: String,
            clazz: String,
            textClass: String,
            index: {type: Number, default: null},
            currentValue: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false}
        },
        methods: {
            onCheckboxChange(){
                if (this.index == null) {
                    this.$emit('change', this.checkboxValue)
                } else {
                    const index = this.index;
                    const checkValue = this.checkboxValue;
                    this.$emit('change', {index,checkValue})

                }

            }
        }
    }
</script>
