<template>
    <div>
        <TopbarCustom></TopbarCustom>
        <section v-if="loadingPayment">
            <LoadingBar clazz="center-floating full-view"
                v-if="!using3DS"
                :loadingText="$t('payment-process.loading-text.process-payment')"
                :extraText="$t('payment-process.loading-text.minutes-message')">
            </LoadingBar>
            <LoadingBar clazz="center-floating full-view"
                v-else
                :loadingText="$t('payment-process.loading-text-3ds.process-payment')"
                :extraText="$t('payment-process.loading-text-3ds.minutes-message')">
                <b-alert class="alert-note m-t-30" show variant="info">
                    <p class="text-container text-left">
                        <i
                            aria-hidden="true"
                            class="icon iconsax-emails-message-text"
                            ></i>
                        {{$t('payment-process.loading-text-3ds.dont-close')}}
                    </p>
                </b-alert>
            </LoadingBar>
        </section>
        <section>
            <vue-snotify></vue-snotify>

            <div>
                <slot name="checkoutContainer"></slot>
            </div>
        </section>
         <section class="footer-single">
             <BottomFloatingNote
                :text="$t('footer.text')"
                strong="Black Labs S.A. de C.V."
                />
        </section>
        <section v-if="doingChallenge">
            <div class="fullscreen-iframe">
                <div class="iframe-container">
                <button class="close-button" aria-label="Boton cerrar ventana" name="Boton cerrar ventana" @click="closeChallenge">
                    <i aria-hidden="true" class="iconsax-essential-close-square"></i>
                </button>
                <!-- Challenge iframe -->
                <iframe title="challenge_iframe" ref="stepup_iframe" id="stepup_iframe" name="stepup_iframe" :class="challengeClass"></iframe>
                    <form ref="stepup_form" id="stepup_form" target="stepup_iframe" method="post" action="">
                        <input ref="stepup_jwt" id="stepup_jwt" type="hidden" name="JWT" value="" />
                        <input ref="stepup_extra_info" type="hidden" name="MD" value=""/>
                    </form>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import LoadingBar from '@/components/LoadingBar.vue';

    const storeModule2 = 'externalPayment';
    import {mapGetters} from 'vuex';

    const storeModule = 'singinup';
    import {EventBus} from '@/main';
    import ToastSnotify from '@/mixins/toastSnotify.mixin';
    import BottomFloatingNote from '@/components/BottomFloatingNote.vue';
    import TopbarCustom from '@/components/Topbar.vue';

    export default {
        name: "baseExternal",
        mixins: [
            ToastSnotify
        ],
        components: {
            LoadingBar,
            BottomFloatingNote,
            TopbarCustom
        },
        data() {
            return {
                // Multiselect
                value: [],
                options: [
                    {name: "Option 1"},
                    {name: "Option 2"},
                    {name: "Option 3"}
                ],
                using3DS: false,
                doingChallenge: false,
                challengeClass: {},
                loadingPayment: false
            }
        },
        mounted() {
            this.$store.commit(`${storeModule2}/SET_TOKEN`, this.$route.query.token);
            this.$store.dispatch(`${storeModule2}/getOrder`, {
                orderId: this.$route.query.orderId
            });
            EventBus.$on(`set-using-3ds`, (using3DS) => {this.using3DS = using3DS});
            EventBus.$on(`loading-payment`, (loading) => {this.loadingPayment = loading});
            EventBus.$on(`start-challenge`, this.startChallenge);
            EventBus.$on(`close-challenge`, this.closeChallenge);
        },
        props: {
            showTittle: {type: Boolean, default: true},
        },

        computed: {
            ...mapGetters(storeModule2, [
                'getPaymentLink', 'getOrder'
            ]),
            ...mapGetters(storeModule, [
                "getUserFound",
                "getUserFullName"
            ]),
        },
        methods: {
            setErrorInfo() {
                if(this.getPaymentLink !== null || !this.getOrder.configCheckout.returnLink) {
                    this.$router.push({
                        name: "paymentLinkShort"
                    });
                } else {
                    this.displayNotificationError(this.$t('externalPayment.baseExternal.errors.get-info'));
                    this.$store.dispatch(`${storeModule}/goBackToOrganization`);
                }
            },
            async startChallenge(data) {
                const { stepUpUrl, orderId, accessToken, frameSize } = data;

                this.doingChallenge = true;
                let frameWidth;
                let frameHeight;
                await this.$nextTick();
                if (frameSize && frameSize !== 'fullScreen') {
                    const [width, height] = frameSize.split('x');
                    frameWidth = width;
                    frameHeight = height;
                    this.$refs.stepup_iframe.width = width;
                    this.$refs.stepup_iframe.height = height;
                    this.challengeClass = {};
                } else {
                    frameWidth = window.innerWidth;
                    frameHeight = window.innerHeight;
                    this.challengeClass = { fullscreen: true };
                }
                this.$refs.stepup_form.action = stepUpUrl;
                this.$refs.stepup_jwt.value = accessToken;
                this.$refs.stepup_extra_info.value = `${orderId};${frameWidth}x${frameHeight}`;
                this.$refs.stepup_form.submit();
            },
            closeChallenge() {
                this.doingChallenge = false;
            },
        },

        created() {
            this.$store.commit(`${storeModule}/SET_EXTERNAL_PAYMENT`, true);
            EventBus.$on(`${storeModule2}-error-get-info`, this.setErrorInfo);
        },
        beforeDestroy() {
            EventBus.$off(`${storeModule2}-error-get-info`, this.setErrorInfo);
            EventBus.$off(`start-challenge`, this.startChallenge);
            EventBus.$off(`close-challenge`, this.closeChallenge);
        }
    };
</script>
