import {
    CLEAR_STORE,
    STORE as sessionStore
  } from "@/store/modules/session/session.types";

import store from '@/store/index';


function _clearSession() {
    store.commit(`${sessionStore}/${CLEAR_STORE}`);
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
        // return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        return `%${(getChartCode(c)).slice(-2)}`;
    }).join(''));

    return JSON.parse(jsonPayload);
}

function getChartCode(c) {
    return `00${c.charCodeAt(0).toString(16)}`;
}


export default function hasUserSession({ next }) {

   const token = localStorage.getItem('currentToken');
   const currentUser = localStorage.getItem('currentUser');
   const user = JSON.parse(currentUser);

    if (token && user !== null) {
        const jwtPayload = parseJwt(token);

        if (jwtPayload.exp < Date.now() / ( 60 * 60 * 24)) {
            // token expired
            _clearSession();

            return next(`/`);
        }

        return next();

    }

    return next(`/`);
}
