import axios from 'axios'
import base from './../base.api';

const namespace = 'pagando';

export default {
    /*
    Function that gives you access to create users, add cards and do payments
     */
    getToken: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-token`, params)
            .then(onSuccess)
            .catch(onError);
    },
    /*
    Function that stores user information
     */
    getUser: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/users/user`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },

    getUserEmail: (params, headers) => {
        return axios.get(`${base.baseUrlPayments}/${namespace}/users/get-user-email`, {params: params, headers: headers})
    },

    login: (params, headers = {}) => {
        return axios.post(`${base.baseUrl}/${namespace}/do-login-api`, params, {headers: headers});
    },
    /*
    Function that save the card information
     */
    addCard: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/payment_methods/add_card`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },
    /*
    Retorna las promociones disponibles para la tarjeta.
     */
    getAviablePromotions: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/promotions/get-terminal-promotions`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },
    /*
    Retorna las promociones disponibles para la tarjeta sin necesidad de usuario.
     */
    getAviablePromotionsWithoutUser: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/promotions/get-terminal-promotions-nouser`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },
    /*
    Function that creates an order to do a payment
     */
    pay: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/orders/create-order`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },

    setValues: (params, headers) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/set-checkout-values`, params, {headers: headers})
    },
    /*
    Function that gets the client order
     */
    getOrder: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/orders/get-order-info-for-checkout`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },
    /*
        Function that generate copy of ecommerce order
     */
    createCopyOrder: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/orders/create-copy-ecommerce-order`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },
    /*
    Function that updates orders reference in complex orders
     */
    updateReferenceComplexOrders: (params, headers, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/orders/update-reference-complex-orders`, params, headers)
            .then(onSuccess)
            .catch(onError);
    },

    getCountries: (params) => {
        return axios.get(`${base.baseUrlPayments}/${namespace}/get-countries`, params);
    },

    /*
      Function that validates info using decision manager
     */
    dmValidate: (params, headers) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/orders/dm-validation`, params, headers);
    },

    authSetup: (params, headers) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/payer_auth/authentication-setup`, params, headers);
    },

    checkEnrollment: (params, headers) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/payer_auth/enrollment-authentication`, params, headers);
    },

    validateChallenge: (params, headers) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/payer_auth/authentication-result`, params, headers);
    },

    /** Pay order after 3DS */
    payOrder: (params, headers) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/orders/pay-order`, params, headers);
    },
}
