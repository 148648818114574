/* eslint-disable no-console */
import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready (registration) {
            console.log('ready', registration)
        },
        registered (registration) {
            console.log('registered', registration)
        },
        cached (registration) {
            console.log('cached', registration)
        },
        updatefound (registration) {
            console.log('updatefound', registration)
        },
        updated (registration) {
            console.log('updated', registration)
        },
        offline () {
            console.log('offline')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
