<template>
    <div v-if="!isCodeForPhone" class="secure-code" :class="[hasError ? 'has-error': '', clazz]">
        <div class="secure-code-content" id="number-code" v-bind:class="containerClass">
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCode.pos1"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos1"
                        v-on:keyup="codeChange($event,1)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCode.pos2"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos2"
                        v-on:keyup="codeChange($event,2)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCode.pos3"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos3"
                        v-on:keyup="codeChange($event,3)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCode.pos4"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos4"
                        v-on:keyup="codeChange($event,4)">
                <span class="caret-underscore"></span>
            </div>
        </div>
        <small v-if="warningText" class="form-text-info">
            {{warningText}}
        </small>
    </div>
    <div v-else class="secure-code" :class="[hasError ? 'has-error': '', clazz]">
        <div class="secure-code-content" id="number-code" v-bind:class="containerClass">
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCodePhone.pos1"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos1"
                        v-on:keyup="codeChange($event,1)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCodePhone.pos2"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos2"
                        v-on:keyup="codeChange($event,2)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCodePhone.pos3"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos3"
                        v-on:keyup="codeChange($event,3)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCodePhone.pos4"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos4"
                        v-on:keyup="codeChange($event,4)">
                <span class="caret-underscore"></span>
            </div>
            <div>
                <input autocomplete="off"
                        :type="typeInput"
                        class="input-space"
                        maxlength="1"
                        v-model="verificationCodePhone.pos5"
                        aria-label=""
                        :placeholder="placeholderInput"
                        ref="pos5"
                        v-on:keyup="codeChange($event,5)">
                <span class="caret-underscore"></span>
            </div>
        </div>
        <small v-if="warningText" class="form-text-info">
            {{warningText}}
        </small>
        <div v-if="resendCode" class="resend-code">
            <button class="button button-ghost button-info">{{$t('payPagandoAccount.resendCode')}}</button>
        </div>
        <div v-if="counter" class="counter">
            <span>{{$t('payPagandoAccount.resendCodeIn')}}</span>
            <div><span class="numbers">{{timerMessage}}</span> seg</div>
        </div>
    </div>
</template>

<script>
import moment from "moment";

    export default {
        name: 'CodeInput',
        data() {
            return {
                noBackspace: 0,
                verificationCode: {
                    pos1: "",
                    pos2: "",
                    pos3: "",
                    pos4: "",
                },
                verificationCodePhone: {
                    pos1: "",
                    pos2: "",
                    pos3: "",
                    pos4: "",
                    pos5: "",
                },
                verificationError: false,
                timerMessage: "02:00",
                resendCode: false,
                counter: false
            }
        },
        props: {
            hasError: {type: Boolean, default: false},
            isCodeForPhone: {type: Boolean, default: false},
            warningText: String,
            clazz: String,
            containerClass: String,
            typeInput: {type: String, default: 'phone'},
            placeholderInput: String
        },
        methods: {
            _codeChange(positionFocus, position){
                let positionFocus1 = positionFocus;
                if(this.isCodeForPhone) {
                    if(position === 5){
                            positionFocus1 = `pos1`;
                            }
                    this.$refs[positionFocus1].focus();
                    if(position < 5)
                        {this.$refs[positionFocus1].select();}
                } else {
                    if(position === 4)
                        {positionFocus1 = `pos1`;}
                    this.$refs[positionFocus1].focus();
                    if(position < 4)
                        {this.$refs[positionFocus1].select();}
                }
            },
            codeChange(event,position){
                const positionName = `pos${position}`;
                if(event && event.target && event.target.value !== "" && event.target.value !== ' ' && !isNaN(event.target.value)){
                    const positionFocus = `pos${position + 1}`;
                    this.verificationError = false;
                    this.verificationCode[positionName] = event.target.value;
                    const codeVerificationArray = Object.values(this.verificationCode);
                    const codeVerificationString = codeVerificationArray.join('');
                    this.$emit('verificationCode', codeVerificationString);
                    this._codeChange(positionFocus, position);
                } else {
                    const codeVerificationArray = Object.values(this.verificationCode);
                    const codeVerificationString = codeVerificationArray.join('');
                    this.$emit('verificationCode', codeVerificationString);
                    this.verificationCode[positionName] = "";
                }
            },
            startCounter() {
                this.resendCode = false;
                this.counter = true;

    //   if (difference <= 0) {
        const dateEntered = moment().add(2, 'minutes').toDate();
        const timer = setInterval(() => {
                    const now = new Date();
                    const difference = dateEntered.getTime() - now.getTime();
                    if (difference <= 0) {
                        clearInterval(timer);
                        this.timerMessage = "00:00";
                        this.resendCode = true;
                        this.counter = false;
                    } else {
                        let seconds = Math.floor(difference / 1000);
                        let minutes = Math.floor(seconds / 60);

                        minutes %= 60;
                        seconds %= 60;

                        if (minutes < 10) {
                        minutes = `0${minutes}`;
                        }

                        if (seconds < 10) {
                        seconds = `0${seconds}`;
                        }

                        this.timerMessage = `${minutes}:${seconds}`;

                    }

                }, 1000);
            }
        },
        created() {
            this.startCounter();
        }

    }
</script>
