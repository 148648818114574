<template>
    <div class="password-input form-element-group" :class="clazz">
        <input :ref="inputRef"
               :id="inputId"
               :type="inputType"
               class="form-element-control"
               :placeholder="inputPlaceholder"
               @input="onInputPassword"
               @keyup.enter="onEnterKey">
        <button v-if="hasViewPasswordIcon"
                class="show-btn"
                @click="onChangePasswordType"
                type="button"
                aria-label="Visualizar contraseña"
                name="Visualizar contraseña">
                <i aria-hidden="true" class="" :class="[isVisibleOn ? 'iconsax-security-eye': 'iconsax-security-eye-slash']"></i>
        </button>

        <div class="input-label">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PasswordInput',
    data() {
        return {
        isVisibleOn: true
        }
    },
    props: {
        help: {type: Boolean, default: false},
        idHelpTooltip: String,
        showlevel: {type: Boolean, default: false},
        clazzPassword: String,
        status: String,
        required: {type: Boolean, default: false},
        label: String,
        helpText: String,
        inputPlaceholder: String,
        helpIcon: String,
        labelFor: String,
        inputId: String,
        clazz: String,
        inputType: String,
        hasLevelBar: {
            type: Boolean,
            default: true
        },
        hasViewPasswordIcon: {
            type: Boolean,
            default: true
        },
        isVisibleText: Boolean,
        focusOnLoad: {
            type: Boolean,
            default: false,
        },
        inputRef: {
            type: String,
            default: "input"
        }
    },
    mounted (){
        if (this.focusOnLoad) {
            this.focusInput()
        }
    },
    methods: {
        onInputPassword(event) {
            this.$emit('passwordInput', event.target.value)
        },
        onChangePasswordType() {
           this.isVisibleOn = !this.isVisibleOn;
            this.$emit('passwordInputType', this.isVisibleOn)
        },
        onEnterKey(){
            this.$emit('enterKey');
        },
        focusInput(){
            try {
                this.$refs[this.inputRef].focus()
            } catch {
                this.$emit('errorFocus');
            }
        },
    }
}
</script>
