import api from '@/api/apiWeb/apiWeb.api'
import ToastSnotify from "@/components/ToastSnotify.vue";
const storeModule = 'externalPayment';
import { EventBus } from '@/main';


const stateGlobal = {
    order: {
        apiType: 'Test',
        organization: {
            name: '',
            image: {
                url: ""
            }
        },
        configCheckout: {
            guestForm: false,
            userForm: false,
        },
        userTestSuccess: '',
        userTestDeclined: '',
    },
    isLoadingOrder: true,
    token: null,
    countries: [],
    paymentLink: null,
    ticket: {
        organizationName: null,
        organizationPhone: null,
        organizationEmail: null,
        transactionNumber: null,
        date: null,
        total: 0,
    }
};


const gettersGlobal = {
    getOrder: (state) => state.order,
    getPaymentTicket: (state) => state.ticket,
    getPaymentLink: (state) => state.paymentLink,
    getIsLoadingOrder: (state) => state.isLoadingOrder,
    getToken: (state) => state.token,
    getCountries: (state) => state.countries,
    getSuccessEmail: (state) => state.order.userTestSuccess,
    getRejectedEmail: (state) => state.order.userTestDeclined,
    getOrganizationImgURL: (state) => {
        if (state.order && state.order.organization && state.order.organization.image) {
            return state.order.organization.image.url
        }
        return null;
    }
};

const actions = {
    getOrder({commit, getters}, params){
        commit('SET_LOADING_ORDER', true);
        api.getOrder(params, {headers: {Authorization: getters.getToken}}, (response) => {
            commit('SET_ORDER_DATA', response.data.data);
            commit('SET_PAYMENT_LINK', response.data.data.paymentLink ? response.data.data.paymentLink._id : null);
            EventBus.$emit(`${storeModule}-load-social`);
        }, () => {
            EventBus.$emit(`${storeModule}-load-social`);
            EventBus.$emit(`${storeModule}-error-get-info`);

        })
    },

    goBackToOrganization({getters}){
        if(getters.getOrder.originECommerce === 'MAGENTO') {
            window.location.replace(`${getters.getOrder.configCheckout.returnLink}?orderId=${getters.getOrder.orderIdECommerce}`);

        } else if (getters.getOrder.originECommerce === 'PAYMENT_LINK'){
            // TODO Ver a donde redireccionar en caso de que sea link de pago.
            window.location.replace(getters.getOrder.configCheckout.returnLink);
        } else {
            window.location.replace(getters.getOrder.configCheckout.returnLink);
        }
    },

    successToOrganization({getters}){
        const confirmUrl = `${getters.getOrder.configCheckout.confirmationLink}?orderId=${getters.getOrder.orderId}`;
        window.location.replace(confirmUrl);
    },

    async getCountries({commit, getters}) {
        if (getters.getCountries.length === 0) {
            const response = await api.getCountries().catch((err) => {
                return {
                    data: err.response.data ? err.response.data : {
                        error: true,
                        message: ''
                    }
                }
            });

            if (response.data.error) {
                EventBus.$emit(`${storeModule}-error-get-countries`);
            } else {
                commit('SET_COUNTRIES', response.data.object)
            }
        }
    },

};

const mutations = {
    SET_ORDER_DATA(state, order){
        state.order = order;
    },
    SET_PAYMENT_TICKET(state, ticket){
        state.ticket = ticket;
    },
    SET_PAYMENT_LINK(state, link){
        state.paymentLink = link;
    },
    SET_LOADING_ORDER(state, isLoading){
        state.isLoadingOrder = isLoading;
    },
    SET_TOKEN(state, token){
        state.token = token;
    },
    SET_COUNTRIES(state, countries){
        state.countries = countries;
    }
};


export default {
    namespaced: true,
    state: {
        ...stateGlobal
    },
    getters: {
        ...gettersGlobal
    },
    actions: {
        ...actions

    },
    mutations: {
        ...mutations
    },
    mixins: [
        ToastSnotify
    ]
};
