import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'users';
const APPLICATION_JSON = 'application/json';

export default {
    findUserTest: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/findUser`, params)
            .then(onSuccess)
            .catch(onError);
    },
    save: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/register`, params)
            .then(onSuccess)
            .catch(onError);
    },
    resendVerificationEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/resend-verification-email`, params)
            .then(onSuccess)
            .catch(onError);
    },
    login: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/login-v2`, params)
            .then(onSuccess)
            .catch(onError);
    },
    loginSocialMedia: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/auth/google/callback`, {params: params})
            .then(onSuccess)
            .catch(onError);
    },
    logout: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/logout-v2`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmEmail`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getCurrentUserSession: (onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-current-user-session`)
            .then(onSuccess)
            .catch(onError);
    },
    getFullActualUser: (onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-full-user`)
            .then(onSuccess)
            .catch(onError);
    },
    confirmFaceookLogin: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmSocialMediaLogin`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getUserByEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-user-by-email`, params)
            .then(onSuccess)
            .catch(onError);
    },
    recoveryPassword: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/recoveryPassword`, params)
            .then(onSuccess)
            .catch(onError);
    },
    resendRestorePasswordEmail: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/resendRestorePasswordEmail`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmPasswordRestore: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmRestorePassword`, params)
            .then(onSuccess)
            .catch(onError)
    },
    confirmPasswordCollaborator: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmPasswordCollaborator`, params)
            .then(onSuccess)
            .catch(onError)
    },
    saveCurrentStepForm: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-step-form`, params, {headers: {'Content-Type': APPLICATION_JSON}})
            .then(onSuccess)
            .catch(onError);
    },
    saveStepOneLevelOne: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-one/save-step-one`, params, {headers: {'Content-Type': APPLICATION_JSON}})
            .then(onSuccess)
            .catch(onError);
    },
    saveStepThreeLevelOne: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-one/save-step-three`, params, {headers: {'Content-Type': APPLICATION_JSON}})
            .then(onSuccess)
            .catch(onError);
    },
    saveStepFourWizard: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-step-four`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmWizardRegister: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/finish-user-wizard`, params)
            .then(onSuccess)
            .catch(onError);
    },
    confirmWizardRegisterLevelOne: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-one/finish-user-wizard`, params)
            .then(onSuccess)
            .catch(onError);
    },
    findOrganizationsProfiles: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/organizations-profiles`, params)
            .then(onSuccess)
            .catch(onError);
    },
    showCurrentUserGeneral: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/show-general/`, params)
            .then(onSuccess)
            .catch(onError);
    },
    updateCurrentUserGeneral: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/update-general/`, params)
            .then(onSuccess)
            .catch(onError);
    },
    changeProfileSettings: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/change-profile`, params)
            .then(onSuccess)
            .catch(onError);
    },
    saveSelfiePhoto: ({selfiePhoto}, onSuccess, onError) => {
        var formData = new FormData();
        formData.append("selfiePhoto", selfiePhoto);
        return axios.post(`${base.baseUrl}/${namespace}/update-selfie`, formData, {headers: {'Content-Type': 'multipart/form-data' }})
            .then(onSuccess)
            .catch(onError);
    },
    getActualTermsPreview: (params, onSuccess, onError) => {
        const user = JSON.parse(localStorage.currentUser);
        return axios.get(`${base.baseUrl}/documents/actual-terms/${user._id}`, params)
            .then(onSuccess)
            .catch(onError);
    },
    acceptPreTerms: (params, onSuccess, onError) => {
        const user = JSON.parse(localStorage.currentUser);
        return axios.post(`${base.baseUrl}/documents/accept-preterms`,{userId: user._id}, params)
            .then(onSuccess)
            .catch(onError);
    },
    retrieveOccupations: (params, onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/sign-up-organization/retrieve/occupations`, params )
            .then(onSuccess)
            .catch(onError);
    },
    recoveryUserBySMS: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/recovery-user-phone`, params )
            .then(onSuccess)
            .catch(onError);
    },
    getCountries: (params, onSuccess, onError) => {
        return axios({
            url: `${base.baseUrl}/${namespace}/get/countries`,
            data: params,
            method: 'GET',
        }).then(onSuccess).catch(onError);
    },
    checkActiveSessions: (onSuccess, onError) => {
        return axios.get(`${base.baseUrl}/${namespace}/check-access`)
            .then(onSuccess)
            .catch(onError);
    },
    disableAccess: (onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/disable-access`)
            .then(onSuccess)
            .catch(onError);
    },
    validateNip: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrl}/${namespace}/validate-pin`, params )
            .then(onSuccess)
            .catch(onError);
    }
}
