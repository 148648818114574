<template>
    <div id="topbar-sidebar-menu-container" class="topbar-sidebar-menu-container">
        <nav id="" class="topbar" :class="clazz">
            <div class="topbar-container">
                <span class="left-side">
                    <router-link to="/" class="" tabindex="">
                        <img class="img-fluid logo" src="@/assets/images/Logos/logo-pagandocheck.svg" width="113" height="40" alt="Logo Pagando check" />
                        <img class="img-fluid logo xs" src="@/assets/images/Logos/logo-pagandocheck.svg" width="113" height="40" alt="Logo Pagando check" />
                    </router-link>
                </span>
                <span class="right-side">
                    <b-dropdown id="dropdown-right" right class="dropdown-language hide-movil">
                        <template #button-content>
                            <button id="" type="button" class="button sm button-ghost button-text_content">
                            <img class="img-fluid m-r-5 brd-2" :src="currentLocale.flag" width="35" height="20" alt="Idioma Español"/>
                            {{$t(currentLocale.title)}}
                            <i aria-hidden="true" class="iconsax-arrow-square-down c-primary m-l-10"></i>
                        </button>
                        </template>
                        <b-dropdown-item
                            v-for="(item, index) in locales"
                            :key="index"
                            :active="item.active"
                            @click="changeLocale(item)"
                        >
                            {{ $t(item.title) }}
                            <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                        </b-dropdown-item>
                    </b-dropdown>

                    <!-- Logged in -->
                    <!-- Add class: active to link-topbar-menu -->
                    <router-link v-if="hasSession"
                                to=""
                                class="link-topbar-menu hide-movil"
                                tabindex="">
                                {{$t('general.help')}}
                    </router-link>
                    <b-dropdown v-if="hasSession" id="dropdown-right" right class="hide-movil">
                        <template #button-content>
                            <button id="" type="button" class="button sm button-ghost button-text_content">
                                {{user.fullName}}
                                <i aria-hidden="true" class="iconsax-arrow-square-down c-primary m-l-10"></i>
                            </button>
                        </template>
                        <b-dropdown-item>
                            <i aria-hidden="true" class="iconsax-location-global"></i>
                            {{$t('general.goToPagandoCheck')}}
                        </b-dropdown-item>
                        <b-dropdown-item>
                            <i aria-hidden="true" class="iconsax-call-incoming"></i>
                            {{$t('general.help')}}
                            <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                        </b-dropdown-item>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item>
                            <i aria-hidden="true" class="iconsax-arrow-logout-halfsquare"></i>
                            {{$t('general.closeSession')}}
                            <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                        </b-dropdown-item>
                    </b-dropdown>

                    <!-- Menu responsive -->
                    <button id=""
                            type="button"
                            class="button-square xs button-ghost button-primary hide-web"
                            aria-label="Menú"
                            name="Menú"
                            @click="menuSidebarOpen">
                        <i aria-hidden="true" class="iconsax-essential-menu"></i>
                    </button>
                </span>
            </div>
        </nav>
        <!-- Menu responsive -->
        <aside id="" class="sidebar-menu-responsive" :class="{'show' : isSidebarOpen}">
            <div class="menu-head text-center">
                <img class="img-fluid logo m-auto" src="@/assets/images/Logos/logo-pagandocheck.svg" width="100" height="36" alt="Logo Remesas" />
            </div>
            <div class="menu-body">
                <label v-if="hasSession" class="sidebar-menu-responsive-name">{{user.fullName}}</label>
                <ul v-if="hasSession" class="sidebar-menu-responsive-list">
                    <li class="active">
                        <router-link to="" class="" tabindex="">
                            <i aria-hidden="true" class="iconsax-location-global m-r-10"></i>
                            {{$t('general.goToPagandoCheck')}}
                            <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                        </router-link>
                    </li>
                    <li>
                        <router-link to="" class="" tabindex="">
                            <i aria-hidden="true" class="iconsax-call-incoming  m-r-10"></i>
                            {{$t('general.help')}}
                            <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                        </router-link>
                    </li>
                    <li class="divider-list"></li>
                    <li>
                        <router-link to="" class="" tabindex="">
                            <i aria-hidden="true" class="iconsax-arrow-logout-halfsquare m-r-10"></i>
                            {{$t('general.closeSession')}}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="menu-footer">
                <b-dropdown id="dropdown-right" top class="dropdown-language">
                    <template #button-content>
                        <button id="" type="button" class="button sm button-ghost button-text_content">
                        <img class="img-fluid m-r-5 brd-2" :src="currentLocale.flag" width="35" height="20" alt="Idioma Español"/>
                        {{$t(currentLocale.title)}}
                        <i aria-hidden="true" class="iconsax-arrow-square-down c-primary m-l-10"></i>
                    </button>
                    </template>
                    <b-dropdown-item
                            v-for="(item, index) in locales"
                            :key="index"
                            :active="item.active"
                            @click="changeLocale(item)"
                        >
                            {{ $t(item.title) }}
                            <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                        </b-dropdown-item>
                </b-dropdown>
            </div>
        </aside>
        <button id=""
                type="button"
                class="close-sidebar-menu-responsive"
                aria-label="Cerrar menú"
                name="Cerrar menú"
                @click="menuSidebarClose">
            <i aria-hidden="true" class="iconsax-arrow-direction-right"></i>
        </button>
        <div class="backdrop-sidebar-menu-responsive"></div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import {
        STORE as SessionStore,
        GET_USER,
        GET_TOKEN
    } from "@/store/modules/session/session.types";
    import { EventBus } from '@/main';

    export default {
        name: 'TopbarCustom',
        props: {
            clazz: String,
            loggedIn: {type: Boolean, default: false}
        },
        data () {
            return {
                isSidebarOpen: false,
                locales: [
                    {
                    title: 'locale.es',
                    value: "es",
                    flag: require("@/assets/images/Icons/flags/Mexico.png"),
                    active: true,
                    },
                    {
                    title: 'locale.en',
                    value: "en",
                    flag: require("@/assets/images/Icons/flags/United_States_of_America.png"),
                    active: false,
                    },
                ],
                currentLocale: null,
                closeSession: false
            }
        },
        methods: {
            menuSidebarOpen () {
                this.isSidebarOpen = !this.isSidebarOpen;
                document.body.classList.add("body-overflow");
            },
            menuSidebarClose () {
                this.isSidebarOpen = !this.isSidebarOpen;
                document.body.classList.remove("body-overflow");
            },
            getCurrentLocale() {
                let locale;
                const localeStorage1 = localStorage.getItem('locale');
                if(localeStorage1) {
                    locale = localeStorage1;
                } else {
                    locale = this.$i18n.locale;
                }

                this.currentLocale = this.locales.find(i => i.value === locale);

                this.changeLocale(this.currentLocale);

                this.changeActiveLocale(this.currentLocale.value);
            },
            changeLocale(locale) {
                this.$i18n.locale = locale.value;
                this.currentLocale = locale;
                this.changeActiveLocale(locale.value);
            },
            changeActiveLocale(value) {
                this.locales.map(i => {
                    if(i.value === value) {
                        i.active = true;
                        localStorage.setItem('locale', value);
                    } else {
                        i.active = false;
                    }

                    return i;
                });
            }
        },
        computed: {
            ...mapGetters(SessionStore, {
                user: GET_USER,
                token: GET_TOKEN
            }),
            hasSession() {
                return this.user && !this.closeSession;
            }
        },
        created() {
            this.getCurrentLocale();

            EventBus.$on(`cleanSession`, () => {
                this.closeSession = true;
            });
        }
    }
</script>
