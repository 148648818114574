import base from '@/api/base.api';
const CreditCardValidator = require("credit-card");

const index = {};
const stack = {};
const deboundeTime = 800;
const BANANA_CHECK_NORMAL = "banana-check-normal";

const imageDownloadLink = function (file) {
    return `${base.baseUrl}/image/download/${file._id || file}`;
}

const debounce = function (func, ...params) {
    const id = func.toString() + JSON.stringify(params || []);
    if (!index[id]) {
        index[id] = true;
        setTimeout(() => {
            index[id] = false;
            if (stack[id]) {
                stack[id].func(...stack[id].params);
                stack[id] = null;
            }
        }, deboundeTime);
        return func(...params);
    }
    stack[id] = {func, params};
    return null;
}

const toast = function(snotify, type, title, message) {
    let icon;
    switch(type){
        case "success":
            icon = BANANA_CHECK_NORMAL;
            break;
        case "error":
            icon = "banana-cross-normal";
            break;
        case "warning":
        case "info":
            icon = "banana-info-circle1";
            break;
        default:
            icon = BANANA_CHECK_NORMAL;
    }
    const content = `
        <span class="icon">
            <i aria-hidden="true" class="banana ${icon}"></i>
        </span>
        <span>
            <div class="snotifyToast__title">${title}</div>
            <div class="snotifyToast__body">
            <p>${message}</p>
            </div>
        </span>
        <a class="close">
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </a>
    `;

    snotify.html(content, {
        timeout: 2000,
        showProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'rightTop',
        type: type
    });
}

const fileDownloadLinkSigned = function (file) {
    return `${base.baseUrl}/file/download/${file._id || file}`;
};

/**
 * Redondea un número para evitar numeros raros de jaascript. Esto se hace debido a que en javascript es muy común
 * terminar con números de la forma 115.999999999
 *
 * @param num {number} a redondear
 * @returns {number} número redondeado
 */
const jsNumFix = function(num) {
    return Math.round( num * 100 + Number.EPSILON ) / 100;
}

const numberToMoneyString = function(number) {
    if(isNaN(number))
    {
        return '$0.00';
    }
    if(typeof number === 'string'){
        number = Number.parseFloat(number);
    }
    return `$${ number.toLocaleString(
        'en-IN',
        {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    )}`;
}

const isDefined = function (value){
    return value !== undefined && value !== null;
}

const CURPRegexString = "[A-Z][A,E,I,O,U,X][A-Z]{2}[0-9]{2}[0-1][0-9][0-3][0-9][M,H][A-Z]{2}[B,C,D,F,G,H,J,K,L,M,N,Ñ,P,Q,R,S,T,V,W,X,Y,Z]{3}[0-9,A-Z][0-9]"
const CURPRegex = new RegExp(CURPRegexString);

const _nextChar = function (c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
};

const _prevChar = function (c) {
    return String.fromCharCode(c.charCodeAt(0) - 1);
};

// Im not sure what the next three methodos do, but I centralized them here and splited them in three in corder to comply with the cognitive complexity rule
const _sequentialLoop1 = function (splitGroup, score) {
    for (const splitGroupElement of splitGroup) {
        let point = 0;
        for (
            let i = 0;
            i < splitGroupElement.split("").length - 1;
            i++
        ) {
            if (splitGroupElement[i] < splitGroupElement[i + 1]) {
                if (
                    _nextChar(splitGroupElement[i]) ===
                    splitGroupElement[i + 1]
                ) {
                    point += 0.5;
                } else {
                    break;
                }
            } else {
                break;
            }
        }

        if (point === 1) {
            score += 1;
        }
    }
    return score;
}
const _sequentialLoop2 = function(splitGroup, score) {
    for (const splitGroupElement of splitGroup) {
        let point = 0;
        for (
            let i = 0;
            i < splitGroupElement.split("").length - 1;
            i++
        ) {
            if (splitGroupElement[i] > splitGroupElement[i + 1]) {
                if (
                    _prevChar(splitGroupElement[i]) === splitGroupElement[i + 1]
                ) {
                    point += 0.5;
                } else {
                    break;
                }
            } else {
                break;
            }
        }

        if (point === 1) {
            score += 1;
        }
    }
    return score;
};
const sequential = function(password) {
    const passwordLowercase = password.toLowerCase();
    let groups = passwordLowercase.match(/[A-Z]+|[a-z]+|[0-9]+/g);
    let score = 0;
    if (groups) {
        groups = groups.filter(function (element) {
            return element.length >= 3;
        });

        for (const group of groups) {
            let splitGroup = [];
            for (let i = 0; i < group.split("").length - 2; i++) {
                splitGroup.push(group.substring(i, i + 3));
            }

            splitGroup = [...new Set(splitGroup)];

            score = _sequentialLoop1(splitGroup, score);
            score = _sequentialLoop2(splitGroup, score);
        }
    }
    return score * 3;
};

const sequentialSymbols = function (password) {
    const groups = password.match(/[^a-zA-z0-9]+/g);
    let score = 0;
    if (groups) {
        let triGroups = [];

        for (const group of groups) {
            triGroups = triGroups.concat(group.match(/.{1,3}/g));
        }

        triGroups = [...new Set(triGroups)];
        triGroups = triGroups.filter(function (element) {
            return element.length === 3;
        });

        score = triGroups.length;
    }

    return score * 3;
}

/**
 * Revisa si un string se encuentra vació, indefinido o nulo
 * @param str
 * @return {boolean}
 */
function isEmptyNullOrItSaysNull(str) {
    return str === undefined || str == null || str === "" || str === "null";
}

const cvvValid = (val) => {
    // WARNING: Cannot use same logic in a single line with a return. (would return "undefined")
    if(val.length === 3 || val.length === 4){
        switch(typeof val){
            case "number":
            return val >= 0 && val < 10000;
            case "string":
            default:
            return val.match(/^[\d]{3,4}$/) !== null;
        }
        }
        return false;
}

const expirationValid = (val) => {
    // WARNING: Cannot use same logic in a single line with a return. (would return "undefined")
    const validExpiryMonth = CreditCardValidator.isValidExpiryMonth(
      val.substring(0, 2)
    );
    const validExpiryYear = CreditCardValidator.isValidExpiryYear(
      `20${val.substring(3, 5)}`
    );
    return val.length === 5 && validExpiryMonth && validExpiryYear;
  };

  const cardNumberValid = (val = "") => {
    const brand = CreditCardValidator.determineCardType(val);
    let isValid;
    if (brand) {
      isValid = CreditCardValidator.isValidCardNumber(val, brand) || false;
    } else {
      isValid = CreditCardValidator.luhn(val) || false;
    }
    // WARNING: Cannot use same logic in a single line with a return. (would return "undefined")
    return (val.length === 15 || val.length === 16) &&
        (isValid || val === "1111111111111111" || val === "2222222222222222");
  };

  const expirationDateValidation = (val) => {
    return /^(0[0-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/.test(val);
  };

export {
    imageDownloadLink,
    debounce,
    toast,
    fileDownloadLinkSigned,
    jsNumFix,
    numberToMoneyString,
    isDefined,
    CURPRegex,
    sequential,
    sequentialSymbols,
    isEmptyNullOrItSaysNull,
    cvvValid,
    expirationValid,
    cardNumberValid,
    expirationDateValidation
}
