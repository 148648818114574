<template>
    <div>
        <baseExternal clazz="full-page" :showTittle="showTittle">
            <template v-slot:checkoutContainer>
                <router-view/>
            </template>
        </baseExternal>
    </div>
</template>

<script>
    import baseExternal from '@/views/externalPayment/baseExternal.vue';
    import { EventBus } from '@/main';
    export default {
        name: "externalPayment",
        data() {
            return {
                showTittle: true
            }
        },
        components: {
            baseExternal
        },
        computed: {},
        methods: {
            switchTittle(value) {
                this.showTittle = value;
            }
        },
        beforeMount(){
            EventBus.$on("externalPaymentSwitchTittle", this.switchTittle);
        },
        beforeDestroy(){
            EventBus.$off("externalPaymentSwitchTittle", this.switchTittle);
        }
    }
</script>
