import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./registerServiceWorker";
// Plugins
// import sentryManager from '@/plugins/sentry';
import '@/plugins/vue-multiselect';
import '@/plugins/bootstrap';
import '@/plugins/vue-snotify';
import '@/plugins/vue-scrollto';
import '@/plugins/vueSession';
import '@/plugins/vuelidate';
import '@/plugins/currencyFilter';
import i18n from '@/plugins/i18n'

import {
    STORE as SessionStore,
    SET_USER,
    SET_TOKEN,
    SET_TOKEN_EXPIRE_DATE,
    SET_CURRENT_LOCATION
} from '@/store/modules/session/session.types';
import base from '@/api/base.api';
import axios from 'axios';
import config from '@/config';
import LoadScript from 'vue-plugin-load-script';
import { v4 as uuidv4 } from 'uuid';
import ToastSnotify from '@/mixins/toastSnotify.mixin';
import NavigationMixin from '@/mixins/navigation.mixin';
import * as requestsOrigins from '@/enums/requestsOrigins.enum';
// const Conekta = window.Conekta;

// Conekta.setPublicKey(config.conekta.publicKey);
axios.defaults.baseURL = base.baseUrl;
Vue.use(axios);
Vue.mixin(ToastSnotify);
Vue.mixin(NavigationMixin);

axios.defaults.headers.common['X-Request-Platform'] = requestsOrigins.webClient;

// Only for vendors css
import '@/plugins/vendors-css';

export const EventBus = new Vue();

Vue.config.productionTip = false;

const token = localStorage.getItem('currentToken');
if(token !== null ){
    axios.defaults.headers.common.Authorization = `Bearer ${token.replace(/"/g, "")}`;
}

// Sentry initialize
// if (sentryManager._isSentryEnabled()) {
//     sentryManager.init();
// }

Vue.use(LoadScript);

if (window.staticApp) {

    new Vue({
        router,
        store,
        i18n
    }).$mount("#app");
} else {
    const beforeMount = function() {
        // Load script for cybersource fingerprint
        const sessionId = uuidv4();
        // Vue.loadScript(`https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=pagando_mx${sessionId}`);
        // Vue.loadScript(`https://h.online-metrix.net/fp/tags.js?org_id=9ozphlqx&session_id=pagando_mx${sessionId}`);
        Vue.loadScript(`https://h.online-metrix.net/fp/tags.js?org_id=${config.fraud.orgId}&session_id=pagando_mx${sessionId}`);
        Vue.prototype.$sessionId = sessionId;

        const currentUser = localStorage.getItem('currentUser');
        const currentToken = localStorage.getItem('currentToken');
        const expireDate = localStorage.getItem('expireDate');

        if(localStorage.getItem('currentLocation') !== null){
            this.$store.commit(`${SessionStore}/${SET_CURRENT_LOCATION}`, JSON.parse(localStorage.getItem('currentLocation')));
        }

        if (currentToken && expireDate) {
            this.$store.commit(`${SessionStore}/${SET_TOKEN}`, JSON.parse(currentToken));
            this.$store.commit(`${SessionStore}/${SET_TOKEN_EXPIRE_DATE}`, JSON.parse(expireDate));
        }
        try {
            if (currentUser) {
                const user = JSON.parse(currentUser)
                this.$store.commit(`${SessionStore}/${SET_USER}`, user);
            }
        } catch (error) {
            console.error(error)
        }
    };

    const mixinWatchRoute = {
        watch: {
            $route (){
                window.scrollTo(0,0);
            }
        }
    }

    new Vue({
        router,
        store,
        i18n,
        beforeMount,
        mixins: [mixinWatchRoute],
        render: h => h(App)
    }).$mount("#app");
}
