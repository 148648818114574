<template>
    <div>
        <div class="horizontal-center">
            <button class="button xs button-filled button-success m-r-10" @click="displayNotificationSuccess()">Toast
                Success
            </button>

            <button class="button xs button-filled button-error m-r-10" @click="displayNotificationError()">Toast
                Danger
            </button>

            <button class="button xs button-filled button-warning m-r-10" @click="displayNotificationWarning()">Toast
                Warning
            </button>

            <button class="button xs button-filled button-info m-r-10" @click="displayNotificationInfo()">Toast Info
            </button>
        </div>

        <vue-snotify></vue-snotify>
    </div>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'ToastSnotify',
        components: {},
        methods: {
            displayNotificationSuccess() {
                this.$snotify.html(
                    `<span class="icon"><i aria-hidden="true" class="iconsax-essential-info-circle"></i></span><span><div class="snotifyToast__title"></div>
                        <div class="snotifyToast__body"><p>Se ha eliminado tu documento: <strong>“Términos y Condiciones Pagando 2019”</strong> satisfactoriamente.</p></div>
                        </span><a class="close" tabindex=""><i aria-hidden="true" class="iconsax-essential-close-square"></i></a>`,
                    {
                    timeout: 50000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: 'rightTop',
                    type: 'success'
                });
            },
            displayNotificationError() {
                this.$snotify.html(
                    `<span class="icon"><i aria-hidden="true" class="iconsax-essential-info-circle"></i></span><span><div class="snotifyToast__title">Lorem ipsum</div>
                        <div class="snotifyToast__body"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p></div></span>
                        <a class="close" tabindex=""><i aria-hidden="true" class="iconsax-essential-close-square"></i></a>`,
                    {
                    timeout: 50000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: 'rightTop',
                    type: 'error'
                });
            },
            displayNotificationWarning() {
                this.$snotify.html(
                    `<span class="icon"><i aria-hidden="true" class="iconsax-essential-info-circle"></i></span><span><div class="snotifyToast__title">Lorem ipsum</div>
                        <div class="snotifyToast__body"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p></div></span>
                        <a class="close" tabindex=""><i aria-hidden="true" class="iconsax-essential-close-square"></i></a>`,
                    {
                    timeout: 50000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: 'rightTop',
                    type: 'warning'
                });
            },
            displayNotificationInfo() {
                this.$snotify.html(
                    `<span class="icon"><i aria-hidden="true" class="iconsax-essential-info-circle"></i></span><span><div class="snotifyToast__title">Lorem ipsum</div>
                        <div class="snotifyToast__body"><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p></div></span>
                        <a class="close" tabindex=""><i aria-hidden="true" class="iconsax-essential-close-square"></i></a>`,
                    {
                    timeout: 50000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: 'rightTop',
                    type: 'info'
                });
            }
        }
    }
</script>
