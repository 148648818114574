const clientHost = process.env.VUE_APP_CLIENT_PROD_HOST || undefined
const clientPort = process.env.VUE_APP_CLIENT_PROD_PORT || undefined
const LOCAL_HOST = 'http://localhost';

export default {
    server: {
        host: process.env.API_HOST || process.env.VUE_APP_API_HOST || LOCAL_HOST,
        port: process.env.API_PORT || process.env.VUE_APP_API_PORT || 3000
    },
    clients: {
        host: process.env.VUE_APP_HOST_CLIENT || LOCAL_HOST,
        port: process.env.VUE_APP_PORT_CLIENT || 8000,
        endpoint: process.env.ENDPOINT_CLIENT || process.env.VUE_APP_ENDPOINT_CLIENT || ''
    },
    users: {
        host: clientHost || process.env.HOST_USER || process.env.VUE_APP_HOST_USER || LOCAL_HOST,
        port: clientPort || process.env.PORT_USER || process.env.VUE_APP_PORT_USER || 8080,
        endpoint: process.env.ENDPOINT_USER || process.env.VUE_APP_ENDPOINT_USER || ''
    },
    admin: {
        host: process.env.HOST_ADMIN || process.env.VUE_APP_HOST_ADMIN || LOCAL_HOST,
        port: process.env.PORT_ADMIN || process.env.VUE_APP_PORT_ADMIN || 8001,
        endpoint: process.env.ENDPOINT_ADMIN || process.env.VUE_APP_ENDPOINT_ADMIN || ''
    },
    externalPayment: {
        host: process.env.HOST_EXTERNAL_PAYMENT || process.env.VUE_APP_HOST_EXTERNAL_PAYMENT|| LOCAL_HOST,
        port: process.env.PORT_EXTERNAL_PAYMENT || process.env.VUE_APP_PORT_EXTERNAL_PAYMENT || 5001,
        endpoint: process.env.ENDPOINT_EXTERNAL_PAYMENT || process.env.VUE_APP_ENDPOINT_EXTERNAL_PAYMENT || ''
    },
    fraud: {
        fingerprint: "b182c6c7-2bea-406f-b7c4-04422317c240",
        orgId: process.env.ORG_ID_CYBER || process.env.VUE_APP_ORG_ID_CYBER || "1snn5n9w",
        threeDS: {
          dataCollectionUrl: process.env.VUE_APP_3DS_DATA_COLLECTION_URL || "https://centinelapistag.cardinalcommerce.com",
        }
    },
    // conekta: {
    //     publicKey: process.env.VUE_APP_CONEKTA_PUBLIC_KEY || "key_KLbyBXBgiQubYyWhvASbAyA",
    // },
    sentry: {
        enabled: true,
        dsn: 'https://f1b67dc0efe04213bad62f95521cfc18@sentry.blacklabs.mx/4'
    },
    trackingTags: {
        googleTagManagerId: 'GTM-5LGB749',
        googleAnalyticsId: 'UA-129970649-1',
        facebookPixelId: '169573313612564'
    }
}
