import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from '@/plugins/i18n/es.js';
import en from '@/plugins/i18n/en.js';
// -------------------------------
// i18n config
// -------------------------------
Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: 'es', // i18n inicial
    fallbackLocale: 'es',
    messages: {
        es: es,
        en: en
    }
});

export default i18n;
