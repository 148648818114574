<template>
    <span class="badge" :class="type">
        <i aria-hidden="true" class="badge-icon banana" :class="icon"></i>
        <slot></slot>
        {{text}}
        <a v-if="closeBtnShow" class="close-badge" tabindex="" aria-label="Cerrar">
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </a>
    </span>
</template>

<script>
    export default {
        name: 'BadgeCustom',
        props: {
            type: String,
            closeBtnShow: {type: Boolean, default: false},
            text: String,
            icon: String
        }
    }
</script>
