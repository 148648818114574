<template>
    <div class="promo-info">
        <div v-if="noPromo" class="promo-title">* {{$t('payCreditDebit.step3.noPromo')}}</div>
        <div v-if="noPromoSelected" class="promo-title">
            {{$t('payCreditDebit.step3.promoMessage')}}
            <a href="https://soporte.pagandocheck.com/" target="_blank" rel="noopener">{{$t('payCreditDebit.step3.promoMessage2')}}</a>
        </div>
        <div v-if="!noPromo && !noPromoSelected" class="promo-title">{{$t('payCreditDebit.step3.selectedPromo')}}</div>
        <div v-if="!noPromo && !noPromoSelected" class="promo">
            <div class="left">
                <strong class="c-info">{{typeOfPromo}}</strong>
                <span>{{amountMoney}}</span>
            </div>
            <div class="right">
                <span>{{$t('payCreditDebit.step3.totalToPay')}}</span>
                <strong>${{total}}</strong>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PromoPreview',
        props: {
            number: String,
            typeOfPromo: String,
            amountMoney: String,
            total: String,
            noPromo: {type: Boolean, default: false},
            noPromoSelected: {type: Boolean, default: false},
        }
    }
</script>
