import axios from 'axios'
import base from '@/api/base.api';

const namespace = 'payment-links';


export default {
    getCurrentURL: (params, onSuccess, onError) => {
        // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        return axios.post(`${base.baseUrlPayments}/${namespace}/generate-link`, params)
            .then(onSuccess)
            .catch(onError);
    },
    getFullCurrentURL: (params, onSuccess, onError) => {
        return axios.post(`${base.baseUrlPayments}/${namespace}/get-full-link`, params)
            .then(onSuccess)
            .catch(onError);
    }
}
