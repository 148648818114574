<template>
    <div class="business-info"  v-bind:class="clazz">
        <div v-if="!horizontal">
            <div class="business-name">
                <div class="business-logo">
                    <img class="img-fluid" :src="logo"
                    width="100" height="74" alt="Logo del negocio" />
                </div>
                <div>
                    <span>{{$t("businessInfo.soldBy")}}</span><br>
                    {{name}}
                </div>
            </div>
            <div v-if="phone" class="info-item">
                <span>{{$t("businessInfo.phone")}}</span>
                <strong>{{phone}}</strong>
            </div>
            <div v-if="mail" class="info-item">
                <span>{{$t("businessInfo.email")}}</span>
                <strong>{{mail}}</strong>
            </div>
        </div>
        <div v-if="horizontal">
            <h3>{{$t("businessInfo.payDetails")}}</h3>
            <div class="panel-container">
                <div class="business-name">
                    <div class="business-logo">
                        <img class="img-fluid" :src="logo"
                        width="100" height="74" alt="Logo del negocio" />
                    </div>
                </div>
                <div class="info-container">
                    <h3>{{$t("businessInfo.payDetails")}}</h3>
                    <div>
                        <div class="info-item">
                            <span>{{$t("businessInfo.soldBy")}}</span>
                            <strong>{{name}}</strong>
                        </div>
                        <div class="info-item">
                            <span>{{$t("businessInfo.contact")}}</span>
                            <div class="contact">
                                <strong v-if="phone">{{phone}}</strong>
                                <strong class="divider" v-if="phone && mail">/</strong>
                                <strong v-if="mail">{{mail}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="total">
                    <span>{{$t("businessInfo.total")}} (MXN)</span>
                    <strong>{{total}}</strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BusinessInfo',
        props: {
            name: String,
            phone: String,
            mail: String,
            logo: String,
            clazz: String,
            total: String,
            horizontal: { type: Boolean, default: false },
        }
    }
</script>
