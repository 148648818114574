<template>
    <div class="panel-checkbox">
        <RadioInput
            @selected="clickSelected()"
            :id="id"
            :currentValue="currentValue"
            :for="id"
        ></RadioInput>
        <div class="card">
            <img class="img-fluid" src="@/assets/images/Cards/card-mastercard.svg"
            width="60" height="43" alt="Imagen de tarjeta" />
        </div>
        <div class="right-info">
            <div class="card-info">
                <strong>Pagando {{account}}</strong>
                {{$t('payPagandoAccount.noAccount')}} ****** {{noCuenta}}
            </div>
            <div class="money-info">
                {{$t('payPagandoAccount.availableBalance')}}
                <strong>{{saldo}}</strong>
            </div>
        </div>
    </div>
</template>

<script>
import RadioInput from '@/components/RadioInput.vue';
import mastercard from '@/assets/images/Cards/card-mastercard.svg';
    export default {
        name: 'PanelCheckbox',
        components: {
            RadioInput
        },
        data() {
            return {
                mastercard: mastercard
            }
        },
        props: {
            account: String,
            noCuenta: String,
            saldo: String,
            id: String,
            currentValue: Boolean,
            product: String
        },
        methods: {
            clickSelected() {
                this.$emit('selected');
            },
            getImage() {
                switch(this.product) {
                    case 'EXPLORE':
                    return mastercard;
                    case 'EXPLORE_PLUS':
                    return null;
                    case 'NIGHT':
                    return mastercard;
                    case 'BUSINESS':
                    return mastercard;
                    case 'BUSINESS_PRO':
                    return mastercard;
                    default:
                    return mastercard;
                }
            }
        }
    }
</script>
