import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger'

import actions from '@/store/actions';
import state from '@/store/state';
import mutations from '@/store/mutations';
import getters from '@/store/getters';

// User
import SingInUpStore from '@/store/modules/signin-up.store';
import {STORE as SessionStore} from '@/store/modules/session/session.types';
import session from '@/store/modules/session/session.store';
import externalPayment from '@/store/modules/externalPayment/externalPayment.store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        singinup: SingInUpStore,
        [SessionStore]: session,
        externalPayment: externalPayment
    },
    actions: actions,
    getters: getters,
    mutations: mutations,
    strict: debug,
    state: state,
    plugins: debug ? [createLogger()] : []
});
