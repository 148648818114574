<template>
    <div>
    <LoadingBar clazz="center-floating full-view d-none"
        loadingText="Tu pago está siendo procesado, estamos analizando las medidas
        de seguridad para que tu compra sea más segura."
        extraText="Esto puede tardar unos minutos…">
            <b-alert class="alert-note m-t-30" show variant="info">
                <p class="text-container text-left">
                    <i
                        aria-hidden="true"
                        class="icon iconsax-emails-message-text"
                        ></i>
                    IMPORTANTE: No cierres esta ventana ni presiones el botón “Atras”
                    o “Actualizar” de tu navegador, para que tu pago pueda ser concluido con éxito.
                </p>
            </b-alert>
        </LoadingBar>
        <TopbarCustom :loggedIn="true"></TopbarCustom>
        <!-- MAIN CONTENT PAGE -->
        <div id="main-view">
            <div class="page-container">
                <div class="row">
                    <div class="col-12">
                        <div class="principal-title">
                            <h1>
                                Paga fácil con Pagando Check
                            </h1>
                            <p>
                                Al realizar tus pagos utilizando Pagando Check,
                                tienes una <strong>protección total de tus datos personales
                                y los datos de tu tarjeta.</strong>
                            </p>
                        </div>
                    </div>

                    <!-- ALERT NOTES -->
                    <div class="col-12">
                        <CardContainer
                            title="Alert Notes"
                            subtitle="Params:">
                            <p>
                                <strong>class:</strong> Extra class for component (String).
                                <br />
                                <strong>dismissible:</strong> Indicates if alert can close.
                                <br />
                                <strong>show:</strong> Indicates if alert shows.
                                <br />
                                <strong>variant:</strong> Indicates alert type (String).
                                <br />
                                <strong>slot:</strong> Aditional content for text.
                            </p>
                            <a
                                href="https://bootstrap-vue.js.org/docs/components/alert"
                                class="f-14 f-bold c-error m-b-30">
                                    <i aria-hidden="true" class></i> See Documentation
                            </a>
                            <div>
                                <button
                                    id
                                    class="button xs button-filled button-primary"
                                    type="button"
                                    v-b-toggle.collapse-alertNotes>
                                    Show Alerts
                                </button>
                                <b-collapse id="collapse-alertNotes" class="m-t-20">
                                    <b-alert class="alert-note" show variant="success">
                                        <p class="text-container">
                                            <i
                                                aria-hidden="true"
                                                class="icon iconsax-emails-message-text"
                                                ></i>
                                            Alert variant Success.
                                        </p>
                                    </b-alert>
                                    <b-alert class="alert-note" show variant="danger">
                                        <p class="text-container">
                                            <i
                                                aria-hidden="true"
                                                class="icon iconsax-emails-message-text"
                                                ></i>
                                            Alert variant Danger.
                                        </p>
                                    </b-alert>
                                    <b-alert class="alert-note" show variant="warning">
                                        <p class="text-container">
                                            <i
                                                aria-hidden="true"
                                                class="icon iconsax-emails-message-text"
                                                ></i>
                                            Alert variant Warning.
                                        </p>
                                    </b-alert>
                                    <b-alert class="alert-note" show variant="info">
                                        <p class="text-container">
                                            <i
                                                aria-hidden="true"
                                                class="icon iconsax-emails-message-text"
                                                ></i>
                                            Alert variant Info.
                                        </p>
                                    </b-alert>
                                </b-collapse>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- BUSINESS INFO -->
                    <div class="col-12">
                        <CardContainer title="Business Information"
                                subtitle="Params:">
                            <p>
                                <strong>logo:</strong> Logo of the business.
                                <br>
                                <strong>name:</strong> Name of the business.
                                <br>
                                <strong>phone:</strong> Phone of the business.
                                <br>
                                <strong>mail:</strong> Mail of the business.
                                <br>
                                <strong>horizontal:</strong> If the component is a panel, this is true.
                                Also you need to add the class .panel to clazz
                                <br>
                                <strong>total:</strong> The total amount of money.
                            </p>
                            <div class="col-12">
                                <businessInfo
                                    :logo="Logo"
                                    name="Monique Fashion"
                                    phone="(614) 123-5678"
                                    mail="finanzas.monique@gmail.com"
                                />
                                <br>
                                <br>
                                <br>
                                <businessInfo
                                    horizontal
                                    clazz="panel"
                                    :logo="Logo"
                                    name="Monique Fashion"
                                    total="1,690.80"
                                    mail="finanzas.monique@gmail.com"
                                />
                            </div>
                        </CardContainer>
                    </div>

                    <!-- BUTTONS -->
                    <div class="col-12">
                        <CardContainer title="Buttons" subtitle="Params:">
                            <div>
                                <button
                                    id=""
                                    class="button xs button-filled button-primary"
                                    type="button"
                                    v-b-toggle.collapse-buttons>
                                    Show Buttons
                                </button>
                                <b-collapse
                                    id="collapse-buttons"
                                    class="m-t-20">
                                    <p>
                                        <strong>Can be used for html tag 'button' and tag 'a'.</strong>
                                        <br/>
                                        Buttons have shape, type, size and color. This is the structure:
                                        <br/>
                                        .shapeButton + .sizeButton + .typeButton + .colorButton
                                        <br/>
                                        Example:
                                        <br/><br/>
                                        class="button xs button-filled button_success"
                                        <br/>
                                        <button class="button xs button-filled button-success"> I'm a button</button>
                                        <br/>
                                        class="button xs button-stroke button-success"
                                        <br/>
                                        <button class="button xs button-stroke button-success"> I'm a button</button>
                                        <br/>
                                        class="button xs button-ghost button-success"
                                        <br/>
                                        <a
                                            class="button xs button-ghost button-success"
                                            tabindex=""
                                        > I'm a button </a>
                                        <br/>
                                        class="button-icon"
                                        <br/>
                                        <a
                                            class="button-icon"
                                            tabindex=""
                                        ><i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>Button</a>
                                        <br/>
                                        <span class="c-error"><strong>NOTE: </strong><strong>There iS NO default button</strong>, classes must be applied for a good result.</span>
                                        <br/><br/>
                                        <strong> SHAPE BUTTONS </strong><br/>
                                        .button<br/>
                                        .button-square<br/>
                                        <strong> SIZE BUTTONS </strong><br/>
                                        .xs<br/>
                                        .sm<br/>
                                        <strong> TYPE BUTTON </strong><br/>
                                        .button-filled<br/>
                                        .button-stroke<br/>
                                        .button-ghost<br/>
                                        <strong> COLOR BUTTONS </strong><br/>
                                        <span class="c-primary">.button-primary</span>
                                        <br>
                                        <span class="c-accent">.button-accent</span>
                                        <br>
                                        <span class="c-text_darker">.button-text_darker</span>
                                        <br>
                                        <span class="c-text_title">.button-text_title</span>
                                        <br>
                                        <span class="c-text_content">.button-text_content</span>
                                        <br>
                                        <span class="c-text_info">.button-text_info</span>
                                        <br>
                                        <span class="c-text_light">.button-text_light</span>
                                        <br>
                                        <span class="c-text_disabled">.button-text_disabled</span>
                                        <br>
                                        <span class="c-text_lightest">.button-text_lightest</span>
                                        <br>
                                        <span class="c-error">.button-error</span>
                                        <br>
                                        <span class="c-success">.button-success</span>
                                        <br>
                                        <span class="c-warning">.button-warning</span>
                                        <br>
                                        <span class="c-info">.button-info</span>
                                        <br>
                                    </p>
                                    <br/>
                                    <h3 class="m-b-20 m-t-20">Button Filled</h3>
                                    <button
                                        type="button"
                                        class="button button-filled button-primary"
                                    >Button
                                    </button>
                                    <br/>
                                    <h3 class="m-b-20 m-t-20">Button Stroke</h3>
                                    <button
                                        type="button"
                                        class="button button-stroke button-primary"
                                    >Button
                                    </button>
                                    <br/>
                                    <h3 class="m-b-20 m-t-20">Button Ghost</h3>
                                    <button
                                        type="button"
                                        class="button button-ghost button-primary"
                                    >Button
                                    </button>
                                    <br/>
                                    <h3 class="m-b-20 m-t-20">Button Icon</h3>
                                    <button
                                        type="button"
                                        class="button-icon"
                                    >
                                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                        Button
                                    </button>
                                    <br/>
                                    <h3 class="m-t-30 m-b-30">BUTTON ADD SQUARE</h3>
                                    <a
                                        href=""
                                        class="button-square-add"
                                        tabindex="">
                                        <i aria-hidden="true" class="iconsax-essential-add-square"></i>
                                        Agregar
                                    </a>
                                </b-collapse>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- CardDetailPayment -->
                    <div class="col-12">
                        <CardContainer
                            title="Card Detail Payment">
                            <div class="row">
                                <div class="col-12">
                                    <CardDetailPayment
                                    title="Detalle de tu pago"
                                    total="1,690.80"
                                    discount="410.00"
                                    totalWithDiscount="1,280.80"
                                    points="20,304"
                                    valuePoints="0.02"
                                    >
                                        <businessInfo
                                            :logo="Logo"
                                            name="Monique Fashion"
                                            phone="(614) 123-5678"
                                            mail="finanzas.monique@gmail.com"
                                        />
                                    </CardDetailPayment>
                                </div>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- COLLAPSABLE -->
                    <div class="col-12">
                        <CardContainer
                            title="Collapsable">
                            <p>
                                Add <strong class="c-primary">.complete</strong>
                                class to <strong class="c-primary">.item</strong> when the step is complete
                            </p>
                            <a
                                href="https://bootstrap-vue.org/docs/components/collapse#accordion-support"
                                class="f-14 f-bold c-error d-block m-b-30"
                            ><i
                                    aria-hidden="true"
                                    class=""
                                ></i> See Documentation </a>
                            <div class="row">
                                <div class="col-12">
                                    <div class="payment-steps">
                                        <a href="#"
                                            type="button"
                                            class="change-payment-method">
                                            <i aria-hidden="true" class="iconsax-arrow-back-square c-primary"></i>
                                            Cambiar forma de pago
                                        </a>
                                         <div class="accordion" role="tablist">
                                            <div class="item complete">
                                                <div class="title-collapsible" v-b-toggle.datos-personales>
                                                    <div class="circle">
                                                        <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                                                    </div>
                                                    <div>
                                                        <span>PASO 01</span>
                                                        <h3>
                                                            Datos personales
                                                        </h3>
                                                    </div>
                                                </div>
                                                <b-collapse class="accordion-container" id="datos-personales" visible accordion="my-accordion" role="tabpanel">
                                                    <div class="row p-t-30">
                                                        <div class="col-12">
                                                            <BasicInput
                                                                required
                                                                label="Correo electrónico"
                                                                inputPlaceholder="Ingresa tu dirección de correo electrónico"
                                                                inputId="correoID">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="Fecha de nacimiento"
                                                                inputPlaceholder="DD / MM / AAAA"
                                                                inputId="dateInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="Número de teléfono"
                                                                inputPlaceholder="(000) 000 - 0000"
                                                                inputId="telInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12">
                                                            <button class="button button-filled button-primary" v-b-toggle.direccion>Continuar</button>
                                                            <button class="button button-filled button-primary disabled" v-b-toggle.direccion>Continuar</button>
                                                        </div>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                            <div class="item">
                                                <div class="title-collapsible" v-b-toggle.direccion>
                                                    <div class="circle">
                                                        <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                                                    </div>
                                                    <div>
                                                        <span>PASO 02</span>
                                                        <h3>
                                                            Dirección
                                                        </h3>
                                                    </div>
                                                </div>
                                                <b-collapse class="accordion-container" id="direccion" accordion="my-accordion" role="tabpanel">
                                                    <div class="row p-t-30">
                                                        <div class="col-12">
                                                            <BasicInput
                                                                required
                                                                label="Dirección"
                                                                inputPlaceholder="Ingresa la calle o avenida"
                                                                inputId="direccionInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicSelect
                                                                required
                                                                label="País"
                                                                selectId="user_pais">
                                                                <multiselect
                                                                    id="user_pais"
                                                                    v-model="valuePais"
                                                                    :options="optionsPais"
                                                                    :close-on-select="true"
                                                                    :preserve-search="true"
                                                                    placeholder="Selecciona el País"
                                                                    label="name"
                                                                    track-by="name"
                                                                    :preselect-first="false">
                                                                    <template
                                                                        slot="selection"
                                                                        slot-scope="{ values, isOpen }">
                                                                        <span
                                                                            class="multiselect__single"
                                                                            v-if="values.length &amp;&amp; !isOpen"
                                                                        >{{ values.length }} options selected</span>
                                                                    </template>
                                                                </multiselect>
                                                            </BasicSelect>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicSelect
                                                                required
                                                                label="Estado/Provincia"
                                                                selectId="user_estados">
                                                                <multiselect
                                                                    id="user_estados"
                                                                    v-model="valueEstado"
                                                                    :options="optionsEstado"
                                                                    :close-on-select="true"
                                                                    :preserve-search="true"
                                                                    placeholder="Selecciona el estado"
                                                                    label="name"
                                                                    track-by="name"
                                                                    :preselect-first="false">
                                                                    <template
                                                                        slot="selection"
                                                                        slot-scope="{ values, isOpen }">
                                                                        <span
                                                                            class="multiselect__single"
                                                                            v-if="values.length &amp;&amp; !isOpen"
                                                                        >{{ values.length }} options selected</span>
                                                                    </template>
                                                                </multiselect>
                                                            </BasicSelect>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="Ciudad"
                                                                inputPlaceholder="Ingresa la ciudad"
                                                                inputId="ciudadInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="Colonia"
                                                                inputPlaceholder="Ingresa la colonia"
                                                                inputId="ciudadInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="No. Exterior"
                                                                inputPlaceholder="0"
                                                                inputId="exteroprInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="No. Interior"
                                                                inputPlaceholder="0"
                                                                inputId="interiorInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12">
                                                            <button class="button button-filled button-primary" v-b-toggle.metodo-pago>Continuar</button>
                                                            <button class="button button-filled button-primary disabled" v-b-toggle.metodo-pago>Continuar</button>
                                                        </div>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                            <div class="item">
                                                <div class="title-collapsible" v-b-toggle.metodo-pago>
                                                    <div class="circle">
                                                        <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                                                    </div>
                                                    <div>
                                                        <span>PASO 03</span>
                                                        <h3>
                                                            Método de pago
                                                        </h3>
                                                    </div>
                                                </div>
                                                <b-collapse class="accordion-container" id="metodo-pago" accordion="my-accordion" role="tabpanel">
                                                    <div class="row p-t-30">
                                                        <div class="col-12">
                                                            <div class="cards-accepted">
                                                                <img class="img-fluid" src="@/assets/images/Cards/card-mastercard.svg"
                                                                width="44" height="29" alt="Mastercard Logo" />
                                                                <img class="img-fluid" src="@/assets/images/Cards/card-visa.svg"
                                                                width="44" height="29" alt="Visa Logo" />
                                                                <img class="img-fluid" src="@/assets/images/Cards/card-carnet.svg"
                                                                width="44" height="29" alt="Carnet Logo" />
                                                                <img class="img-fluid" src="@/assets/images/Cards/card-amex.svg"
                                                                width="44" height="29" alt="AMEX Logo" />
                                                                Tarjetas aceptadas
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <BasicInput
                                                                required
                                                                label="Nombre del titular de la tarjeta"
                                                                inputPlaceholder="Nombre completo"
                                                                inputId="titularID">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12">
                                                            <BasicInput
                                                                required
                                                                label="Número de la tarjeta"
                                                                inputPlaceholder="XXXX XXXX XXXX XXXX"
                                                                inputId="tarjetaID">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="Fecha de vencimiento"
                                                                inputPlaceholder="MM / AA"
                                                                inputId="fechaInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <BasicInput
                                                                required
                                                                label="CVV/CVC"
                                                                inputPlaceholder="Código de seguridad"
                                                                inputId="codigoInput">
                                                            </BasicInput>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="divider"></div>
                                                        </div>
                                                        <div class="col-12">
                                                            <LoadingBar loadingText="Buscando promociones disponibles..."></LoadingBar>
                                                        </div>
                                                        <div class="col-12">
                                                            <BasicSelect
                                                                clazz="payment-select m-b-15"
                                                                label="Promociones disponibles"
                                                                selectId="promosID">
                                                                <multiselect
                                                                    id="promosID"
                                                                    v-model="valuePromo"
                                                                    :options="optionsPromo"
                                                                    :close-on-select="true"
                                                                    :preserve-search="true"
                                                                    placeholder="Selecciona una promoción"
                                                                    label="paymentPromo"
                                                                    track-by="paymentPromo"
                                                                    group-label="typePromo"
                                                                    group-values="promo"
                                                                    :preselect-first="false">
                                                                    <span slot="noResult">No hay resultados para tu búsqueda</span>
                                                                    <template slot="singleLabel" slot-scope="props">
                                                                        <span class="option__title">{{ props.option.paymentPromo }}</span>
                                                                    </template>
                                                                    <template slot="option" slot-scope="props">
                                                                        <span class="group-title" v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>
                                                                        <div class="payment-detail-select">
                                                                            <div class="info-container">
                                                                                <strong class="payment">{{ props.option.paymentPromo }}</strong><br>
                                                                                <span>{{ props.option.paymentMoney }}</span>
                                                                            </div>
                                                                            <div class="info-container">
                                                                                <span>Total a pagar</span><br>
                                                                                <strong>${{ props.option.total }}</strong>
                                                                            </div>
                                                                        </div>
                                                                    </template>
                                                                </multiselect>
                                                                <div class="info-tooltip" id="inf-tooltip">
                                                                    <i aria-hidden="true" class="iconsax-essential-tick-circle"></i>
                                                                    <b-tooltip
                                                                        target="inf-tooltip"
                                                                        placement="right"
                                                                        custom-class="white-tooltip"
                                                                    >
                                                                        <p class="m-b-0">
                                                                            ¿Cuáles son las promociones y bancos
                                                                            disponibles que participan en esta
                                                                            modalidad de pago?
                                                                            <br>
                                                                            <a href="#">Ir a preguntas frecuentes</a>
                                                                        </p>
                                                                    </b-tooltip>
                                                                </div>
                                                            </BasicSelect>
                                                        </div>
                                                        <div class="col-12">
                                                            <promoPreview
                                                            number="6"
                                                            typeOfPromo="Meses sin intereses"
                                                            amountMoney="218.00"
                                                            total="1,232.80"/>
                                                        </div>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <a href="#"
                                        type="button"
                                        class="button button-ghost button-info">
                                        <i aria-hidden="true" class="iconsax-arrow-circle-left-1 m-r-10 c-primary"></i>
                                        Volver a las opciones de pago
                                    </a>
                                </div>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- FORM ELEMENTS -->
                    <div class="col-12">
                        <CardContainer title="Form elements"
                                        subtitle="Form elements like inputs, multiselects, pasword input, code input, etc.">
                            <div>
                                <button
                                    id
                                    class="button xs button-filled button-primary"
                                    type="button"
                                    v-b-toggle.collapse-formElements>
                                    Show Form Elements
                                </button>
                                <b-collapse
                                    id="collapse-formElements"
                                    class="m-t-20">
                                    <h3 class="m-b-20 m-t-20">Read Only Text</h3>
                                    <p>
                                        <strong>Params:</strong>
                                        <br />
                                        <br />
                                        <strong>clazz:</strong> Extra class for component (String).
                                        <br />
                                        <strong>title:</strong> Text for title (String).
                                        <br />
                                        <strong>text:</strong> Text for subtitle text (String).
                                        <br />
                                    </p>
                                    <ReadOnlyInfo
                                        title="Title:"
                                        text="Text"
                                    ></ReadOnlyInfo>
                                    <h3 class="m-b-20 m-t-20">Input basic</h3>
                                    <p>
                                        <strong>idForm:</strong> ID for input's container (String).
                                        <br />
                                        <strong>clazz:</strong> Extra class for input's container (String).
                                        <br />
                                        <strong>hasError:</strong> Class for input's error style (Boolean).
                                        <br />
                                        <strong>inputId</strong> ID for input (String).
                                        <br />
                                        <strong>inputType:</strong> Input's type (String).
                                        <br />
                                        <strong>inputClazz:</strong> Extra class for input (String).
                                        <br />
                                        <strong>name:</strong> Input's name (String).
                                        <br />
                                        <strong>value:</strong> Input's value (String, Number).
                                        <br />
                                        <strong>inputPlaceholder:</strong> Input's placeholder (String).
                                        <br />
                                        <strong>disabled:</strong> Input's disabled property (Boolean).
                                        <br />
                                        <strong>labelClazz:</strong> Extra class for label (String).
                                        <br />
                                        <strong>label:</strong> Text for label (String).
                                        <br />
                                        <strong>required:</strong> If input is required (Boolean).
                                        <br />
                                        <strong>v-slot:extraContent</strong> Input's extra content.
                                        <br />
                                        <strong>slot:</strong> Extra content.
                                    </p>
                                    <BasicInput
                                        required
                                        label="Simple input example"
                                        inputPlaceholder="Input placeholder example"
                                        inputId="input_example">
                                    </BasicInput>
                                    <BasicInput
                                        required
                                        :hasError="true"
                                        label="Simple input example"
                                        inputPlaceholder="Input placeholder example"
                                        inputId="input_example">
                                    </BasicInput>
                                    <BasicInput
                                        required
                                        label="Simple input example"
                                        inputPlaceholder="Input placeholder example"
                                        inputId="input_disabled"
                                        disabled>
                                    </BasicInput>
                                    <BasicInput
                                        required
                                        label="Número de tarjeta"
                                        inputPlaceholder="0000 - 0000 - 0000 - 0000"
                                        inputId="num-tarjeta"
                                        inputClazz="inputExtraContent">
                                        <template v-slot:extraContent>
                                            <img class="img-fluid cardtypeinput" src="@/assets/images/Cards/card-visa.svg" width="42" height="22" alt="Visa Logo" />
                                        </template>
                                    </BasicInput>
                                    <BasicInput
                                        required
                                        label="Número de tarjeta"
                                        inputPlaceholder="0000 - 0000 - 0000 - 0000"
                                        inputId="num-tarjeta"
                                        inputClazz="inputExtraContent">
                                        <template v-slot:extraContent>
                                            <img class="img-fluid cardtypeinput" src="@/assets/images/Cards/card-mastercard.svg" width="42" height="22" alt="Visa Logo" />
                                        </template>
                                    </BasicInput>
                                    <BasicInput
                                        required
                                        label="Clabe Interbancaria / No. Tarjeta / Teléfono / Correo electrónico"
                                        inputPlaceholder="Ingresar dato"
                                        inputId=""
                                        inputClazz="extraContentButton">
                                        <template v-slot:extraContent>
                                            <button id=""
                                                type="button"
                                                class="button xs button-filled button-primary">
                                                Buscar
                                            </button>
                                        </template>
                                    </BasicInput>
                                    <BasicInput
                                        required
                                        label="Clabe Interbancaria / No. Tarjeta / Teléfono / Correo electrónico"
                                        inputPlaceholder="Ingresar dato"
                                        inputId=""
                                        inputClazz="extraContentButton">
                                        <template v-slot:extraContent>
                                            <button id=""
                                                type="button"
                                                class="button xs button-filled button-primary">
                                                Enviar invitación
                                            </button>
                                        </template>
                                    </BasicInput>
                                    <BasicInput
                                        required
                                        label="Clabe Interbancaria / No. Tarjeta / Teléfono / Correo electrónico"
                                        inputPlaceholder="Ingresar dato"
                                        inputId=""
                                        inputClazz="extraContentButton">
                                        <template v-slot:extraContent>
                                            <button id=""
                                                type="button"
                                                class="button xs button-filled button-primary">
                                                Buscando...
                                            </button>
                                        </template>
                                    </BasicInput>
                                    <BasicInput
                                        inputPlaceholder="Ingresar código de transacción"
                                        inputId="search_transaction"
                                        inputClazz="search-input">
                                        <template v-slot:extraContent>
                                            <button id=""
                                                type="button"
                                                class="button xs button-filled button-primary">
                                                Buscar
                                            </button>
                                        </template>
                                        <i aria-hidden="true" class="search-input-icon iconsax-search-normal-2"></i>
                                    </BasicInput>
                                    <br/><br/>

                                    <h3 class="m-b-20 m-t-20">Multiselect</h3>
                                    <p>
                                        <strong>idForm:</strong> ID text for select container (String).
                                        <br />
                                        <strong>clazz:</strong> Extra class for select container (String).
                                        <br />
                                        <strong>hasError:</strong> Class for select's error style (Boolean).
                                        <br />
                                        <strong>labelClazz:</strong> Extra class for label (String).
                                        <br />
                                        <strong>selectId:</strong>  Select's ID text for label (String).
                                        <br />
                                        <strong>label:</strong> Text for label (String).
                                        <br />
                                        <strong>required:</strong> If select is required (Boolean).
                                        <br />
                                        <strong>slot:</strong> To add multiselect component.
                                        <br />
                                    </p>
                                    <a href="https://vue-multiselect.js.org/#sub-getting-started"
                                        class="f-14 f-bold c-error d-block m-b-30"
                                        tabindex="">
                                        See Documentation
                                    </a>
                                    <BasicSelect
                                        required
                                        label="Género (Opcional)"
                                        selectId="user_genero">
                                        <multiselect
                                            id="user_genero"
                                            v-model="valueGenero"
                                            :options="optionsGenero"
                                            :close-on-select="true"
                                            :preserve-search="true"
                                            placeholder="Selecciona el género"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false">
                                            <template
                                                slot="selection"
                                                slot-scope="{ values, isOpen }">
                                                <span
                                                    class="multiselect__single"
                                                    v-if="values.length &amp;&amp; !isOpen"
                                                >{{ values.length }} options selected</span>
                                            </template>
                                        </multiselect>
                                    </BasicSelect>
                                    <BasicSelect
                                        :hasError="true"
                                        required
                                        label="Género (Opcional)"
                                        selectId="user_genero">
                                        <multiselect
                                            id="user_genero"
                                            v-model="valueGenero"
                                            :options="optionsGenero"
                                            :close-on-select="true"
                                            :preserve-search="true"
                                            placeholder="Selecciona el género"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false">
                                            <template
                                                slot="selection"
                                                slot-scope="{ values, isOpen }">
                                                <span
                                                    class="multiselect__single"
                                                    v-if="values.length &amp;&amp; !isOpen"
                                                >{{ values.length }} options selected</span>
                                            </template>
                                        </multiselect>
                                    </BasicSelect>
                                    <BasicSelect
                                        required
                                        label="Género (Opcional)"
                                        selectId="user_genero">
                                        <multiselect
                                            id="user_genero"
                                            v-model="valueGenero"
                                            :options="optionsGenero"
                                            :close-on-select="true"
                                            :preserve-search="true"
                                            placeholder="Selecciona el género"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false"
                                            disabled>
                                            <template
                                                slot="selection"
                                                slot-scope="{ values, isOpen }">
                                                <span
                                                    class="multiselect__single"
                                                    v-if="values.length &amp;&amp; !isOpen"
                                                >{{ values.length }} options selected</span>
                                            </template>
                                        </multiselect>
                                    </BasicSelect>
                                        <BasicSelect
                                        label="Multiselect w/tags">
                                        <multiselect
                                            v-model="valueTags"
                                            :options="optionsTags"
                                            placeholder="Elige los tags a incluir"
                                            tag-placeholder="Agregar como nuevo tag"
                                            label="name"
                                            track-by="name"
                                            :preselect-first="false"
                                            class=""
                                            :multiple="true"
                                            :taggable="true"
                                        ></multiselect>
                                    </BasicSelect>
                                    <br/><br/>

                                    <h3 class="m-b-20 m-t-20">Code input</h3>
                                    <p>
                                        <strong>clazz:</strong> Extra class for component (String).
                                        <br />
                                        <strong>hasError:</strong> Class for input's error style (Boolean).
                                        <br />
                                        <strong>containerClass:</strong> Extra class for container (String).
                                        <br />
                                        <strong>typeInput:</strong> Input's type, default: text (String).
                                        <br />
                                        <strong>warningText:</strong> Text for warning (String).
                                    </p>
                                    <CodeInput showWarning
                                        warningText="El SMS puede tardar unos minutos en llegar,
                                        si no lo has recibido puedes presionar el siguiente link:"></CodeInput>
                                    <CodeInput :hasError="true" showWarning
                                        warningText="El SMS puede tardar unos minutos en llegar,
                                        si no lo has recibido puedes presionar el siguiente link:"></CodeInput>
                                    <br/><br/>

                                    <h3 class="m-b-20 m-t-20">Password input</h3>
                                    <p>
                                        <strong>clazz:</strong> Extra class for component (String).
                                        <br />
                                        <strong>hasError:</strong> Class for input's error style (Boolean).
                                        <br />
                                        <strong>inputId:</strong> ID for input (String).
                                        <br />
                                        <strong>inputType:</strong> Input's type, default password (String).
                                        <br />
                                        <strong>inputPlaceholder:</strong> Input's placeholder (String).
                                        <br />
                                        <strong>disabled:</strong> Input's disabled property (Boolean).
                                        <br />
                                        <strong>labelClazz:</strong> Extra class for label (String).
                                        <br />
                                        <strong>label:</strong> Text for label (String).
                                        <br />
                                        <strong>required:</strong> If input is required (Boolean).
                                        <br />
                                        <strong>hasLevelBar:</strong> To hide level bar component (Boolean).
                                        <br />
                                        <strong>clazzPassword:</strong> Class for level bar component (String).
                                        <br />
                                        <strong>status:</strong> Text status for level bar component (String).
                                    </p>
                                    <PasswordInput
                                                required
                                                label="Contraseña"
                                                inputPlaceholder="Mínimo 6 caracteres con mayúsculas, minúsculas, números y símbolos"
                                                inputId="user_psw"
                                                clazzPassword="weak"
                                                status="Muy débil">
                                    </PasswordInput>
                                    <PasswordInput
                                                required
                                                :hasError="true"
                                                label="Contraseña"
                                                inputPlaceholder="Mínimo 6 caracteres con mayúsculas, minúsculas, números y símbolos"
                                                inputId="user_psw"
                                                clazzPassword="regular"
                                                status="Contraseña regular">
                                    </PasswordInput>
                                    <PasswordInput
                                                required
                                                disabled
                                                label="Contraseña"
                                                inputPlaceholder="Mínimo 6 caracteres con mayúsculas, minúsculas, números y símbolos"
                                                inputId="user_psw"
                                                clazzPassword="good"
                                                status="Buena Contraseña">
                                    </PasswordInput>
                                    <PasswordInput
                                                required
                                                disabled
                                                label="Contraseña"
                                                inputPlaceholder="Mínimo 6 caracteres con mayúsculas, minúsculas, números y símbolos"
                                                inputId="user_psw"
                                                clazzPassword="excellent"
                                                status="Excelente Contraseña">
                                    </PasswordInput>
                                    <br/><br/>
                                    <h3 class="f-18 body-font-bold c-accent_2">Radio input</h3>
                                    <p class="f-14">
                                        Radio input used on avocado.
                                        <br>
                                        <strong>Parameters:</strong>
                                        <br>
                                        <br>
                                        <strong>idInput:</strong> It refers to the Radio input ID.
                                        <br>
                                        <strong>text:</strong> It refers to the Radio input description.
                                        <br>
                                        <strong>clazz:</strong> Extra class for component (String).
                                        <br>
                                        <strong>inputClass:</strong> Extra class for input (String).
                                        <br>
                                        <strong>disabled:</strong> For disable component (String).
                                        <br>
                                        <strong>spanClazz:</strong> Extra class for text (String).
                                        <br>
                                        <strong>isHiddenText:</strong> To hide text (Boolean).
                                    </p>
                                    <RadioInput
                                        idInput="one"
                                        text="Opcion 1"
                                    ></RadioInput>
                                    <RadioInput
                                        idInput="two"
                                        text="Opcion 2"
                                    ></RadioInput>
                                    <PanelCheckbox
                                    account="Clásica"
                                    noCuenta="4324"
                                    saldo="2,500.00"
                                    />
                                    <PanelCheckbox
                                    account="Explore"
                                    noCuenta="4324"
                                    saldo="2,500.00"
                                    />
                                    <br/><br/>
                                    <h3 class="f-18 body-font-bold c-accent_2">Checkbox</h3>
                                    <p class="f-14">
                                        Checkbox used on avocado.
                                        <br>
                                        <strong>Parameters:</strong>
                                        <br>
                                        <strong>clazz:</strong> Extra class for component (String).
                                        <br>
                                        <strong>checkId:</strong> Id for input (String).
                                        <br>
                                        <strong>inputClass:</strong> Extra class for input (String).
                                        <br>
                                        <strong>disabled:</strong> To disable component.
                                        <br>
                                        <strong>text:</strong> It refers to the Checkbox text (String).
                                        <br>
                                        <strong>textClass:</strong> Extra class for text (String).
                                    </p>
                                    <BasicCheckbox text="Text sin index"></BasicCheckbox>
                                    <BasicCheckbox
                                        text="Text index 0"
                                        :index="0"
                                        :currentValue="true"
                                    ></BasicCheckbox>
                                    <BasicCheckbox
                                        text="Text index 1"
                                        :index="1"
                                    ></BasicCheckbox>

                                </b-collapse>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- LIST -->
                    <div class="col-12">
                        <CardContainer title="List"
                                subtitle="Params:">
                            <p>
                                <strong>clazz:</strong> Add an extra class.
                                <br>
                                <strong>loadingText:</strong> Text.
                                <br>
                            </p>
                            <div class="col-12">
                                <ul class="list">
                                    <li>
                                        <i aria-hidden="true" class="iconsax-arrow-circle-right-1"></i>
                                        <div>
                                            Ve a tu aplicación de banco o banca en línea y elige la opción
                                            <span>"Transferencia a otros bancos".</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="iconsax-arrow-circle-right-1"></i>
                                        <div>
                                            Elige como banco destino a:
                                            <span>"Sistema de Transferencias y Pagos".</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="iconsax-arrow-circle-right-1"></i>
                                        <div>
                                            En el campo CLABE ingresa los 18 dígitos:
                                            <span>646118012260000735</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="iconsax-arrow-circle-right-1"></i>
                                        <div>
                                            En el campo Referencia ingresa el número de orden:
                                            <span>2000014</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="iconsax-arrow-circle-right-1"></i>
                                        <div>
                                            Ingresa el nombre del beneficiario:
                                            <span>Monique Fashion</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="iconsax-arrow-circle-right-1"></i>
                                        <div>
                                            Realiza la transferencia.
                                        </div>
                                    </li>
                                    <li>
                                        <i aria-hidden="true" class="iconsax-arrow-circle-right-1"></i>
                                        <div>
                                            ¡Listo!, a los pocos minutos de realizar la transferencia te notificaremos del pago realizado.
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- LOADING SIMPLE -->
                    <div class="col-12">
                        <CardContainer title="Loading"
                                subtitle="Params:">
                            <p>
                                <br><br>
                                <strong>clazz:</strong> Add an extra class.
                                <br>
                                <strong>loadingText:</strong> Text.
                                <br>
                            </p>
                            <div class="col-12 m-b-40">
                                <LoadingBar loadingText="Loading text..."></LoadingBar>
                            </div>
                            <div class="col-12 p-relative">
                                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                               <LoadingBar clazz="center-floating" loadingText="Floating center"></LoadingBar>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- MAIL -->
                    <div class="col-12">
                        <CardContainer title="Mails"
                                subtitle="All the mails are on sendgrid, if you need help please contact a member of the UI team.">
                            <a
                                href="https://app.sendgrid.com/"
                                class="f-14 f-bold c-error d-block m-b-30"
                                ><i aria-hidden="true" class=""></i> Sendgrid
                            </a>
                        </CardContainer>
                    </div>

                    <!-- MODALS -->
                    <div class="col-12">
                        <CardContainer
                            title="Modals">
                            <h2 class="f-18 body-font-bold c-accent_2">Modal Various</h2>
                            <p>
                                <strong>Params:</strong>
                                <br /><br />
                                <strong>id:</strong> Modal's ID (String).
                                <br />
                                <strong>size:</strong> Modal's size: sm, lg, xl (String).
                                <br />
                                <strong>hide-header / hide-footer:</strong> Params to hide header
                                and footer.
                                <br />
                                <strong>
                                    no-close-on-esc / no-close-on-backdrop / hide-header-close:
                                </strong> Params to stop default closing with ESC, click backdrop and click close icon.
                                <br />
                                <strong>slot</strong> Aditional content for modal's body.
                            </p>
                            <a
                                href="https://bootstrap-vue.js.org/docs/components/modal"
                                class="f-14 f-bold c-error d-block m-b-30"
                                ><i aria-hidden="true" class=""></i> See Documentation
                            </a>
                            <div class="horizontal-center m-b-20">
                                <button
                                    id=""
                                    class="button xs button-filled button-primary m-r-10 m-b-10"
                                    @click="$bvModal.show('modal-example')"
                                    type="button"
                                >
                                    Ejemplo
                                </button>
                                <button
                                    id=""
                                    class="button xs button-filled button-primary m-r-10 m-b-10"
                                    @click="$bvModal.show('modal-iniciarSesion')"
                                    type="button"
                                >
                                    Iniciar sesion
                                </button>
                                <button
                                    id=""
                                    class="button xs button-filled button-primary m-r-10 m-b-10"
                                    @click="$bvModal.show('modal-sms')"
                                    type="button"
                                >
                                    Codigo SMS
                                </button>
                                <button
                                    id=""
                                    class="button xs button-filled button-primary m-r-10 m-b-10"
                                    @click="$bvModal.show('modal-nip')"
                                    type="button"
                                >
                                    NIP
                                </button>
                                <button
                                    id=""
                                    class="button xs button-filled button-primary m-r-10 m-b-10"
                                    @click="$bvModal.show('modal-loading')"
                                    type="button"
                                >
                                    Modal code loading example
                                </button>
                            </div>

                            <!-- MODAL EXAMPLE -->
                            <b-modal
                                id="modal-example"
                                size="lg"
                                hide-header
                                hide-footer
                                modal-class="modal-default left-oriented">
                                <button class="close-modal"
                                        @click="$bvModal.hide('modal-example')"
                                        aria-label="Boton cerrar ventana"
                                        name="Boton cerrar ventana">
                                    <i aria-hidden="true" class="banana banana-cross-normal"></i>
                                </button>
                                <div class="head">
                                    <h2 class="modal-title">Lorem ipsum</h2>
                                    <p class="modal-subtitle">
                                        Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                    </p>
                                </div>
                                <div class="body">
                                    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                    Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum
                                </div>
                                <div class="footer">
                                    <button type="button" class="button button-stroke button-primary">Cancelar</button>
                                    <button type="button" class="button button-filled button-primary" disabled>Continuar</button>
                                </div>
                            </b-modal>

                            <!-- MODAL INICIAR SESION -->
                            <b-modal
                                id="modal-iniciarSesion"
                                size="xl"
                                hide-header
                                hide-footer
                                modal-class="modal-default signin-modal">
                                <button class="close-modal"
                                        @click="$bvModal.hide('modal-iniciarSesion')"
                                        aria-label="Boton cerrar ventana"
                                        name="Boton cerrar ventana">
                                    <i aria-hidden="true" class="iconsax-essential-close-square"></i>
                                </button>
                                <div class="body">
                                    <div class="left">
                                        <div class="description">
                                            <img class="img-fluid" src="@/assets/images/Logos/logo-pagando.svg"
                                            width="150" height="34" alt="Logo pagando" />
                                            <p>
                                                Abre una cuenta a tu medida sin acudir a una sucursal.
                                                Realiza tus <strong>compras y pagos de servicios por internet</strong> o
                                                en tus negocios favoritos con tu <strong>tarjeta Mastercard.</strong>
                                            </p>
                                        </div>
                                        <img class="img-fluid person" src="@/assets/images/Modals/sign-in-image.png"
                                        width="400" height="400" alt="Persona pagando" />
                                    </div>
                                    <div class="right">
                                        <div class="modal-title-big">
                                            <h1>
                                                Pagar con Pagando
                                            </h1>
                                            <p>
                                                Ingresa tus datos para iniciar sesión en tu cuenta Pagando
                                                y gozar de los beneficios de tu cuenta y protección de tus datos.
                                            </p>
                                        </div>
                                        <UploadProfileImage
                                            imgUser
                                            imgUploaded
                                            showInfoUser
                                            clazz="horizontal-info xs user-verification-login"
                                            userName="“La vida es mejor Pagando”"
                                        ></UploadProfileImage>
                                        <PasswordInput
                                            :hasLevelBar="false"
                                            required
                                            label="Contraseña"
                                            inputPlaceholder="Ingresar contraseña"
                                            inputId="user_psw">
                                        </PasswordInput>
                                        <button class="button button-filled button-primary w-100 m-b-10 disabled">Iniciar sesión</button>
                                        <button class="button button-ghost button-primary w-100">Regresar</button>
                                        <span class="bottom-link">
                                            ¿Todavía no estás registrado?
                                            <router-link to="#" class="" tabindex="">
                                                Crea una cuenta
                                            </router-link>
                                        </span>
                                    </div>
                                </div>
                            </b-modal>

                            <!-- MODAL SMS -->
                            <b-modal
                                id="modal-sms"
                                size=""
                                hide-header
                                hide-footer
                                modal-class="modal-default code-modal">
                                <button class="close-modal"
                                        @click="$bvModal.hide('modal-sms')"
                                        aria-label="Boton cerrar ventana"
                                        name="Boton cerrar ventana">
                                    <i aria-hidden="true" class="iconsax-essential-close-square"></i>
                                </button>
                                <div class="body">
                                    <div class="modal-title-small">
                                        <h1>
                                            Ingresa código de confirmación
                                        </h1>
                                        <p>
                                            Hemos enviado un mensaje SMS con tu código al número celular
                                            <br>
                                            <strong>(614) 192-2784</strong>
                                        </p>
                                    </div>
                                    <CodeInput showWarning counter
                                        warningText="El SMS puede tardar unos minutos en llegar,
                                        si no lo has recibido puedes presionar el siguiente link:"></CodeInput>
                                    <CodeInput :hasError="true" showWarning resendCode
                                        warningText="El código que has ingresado no es válido. Inténtalo de nuevo."></CodeInput>
                                    <div class="btn-container">
                                        <button class="button button-ghost button-text_info">Cancelar</button>
                                        <button class="button button-filled button-primary disabled">Confirmar</button>
                                    </div>
                                </div>
                            </b-modal>

                            <!-- MODAL NIP -->
                            <b-modal
                                id="modal-nip"
                                size=""
                                hide-header
                                hide-footer
                                modal-class="modal-default code-modal">
                                <button class="close-modal"
                                        @click="$bvModal.hide('modal-nip')"
                                        aria-label="Boton cerrar ventana"
                                        name="Boton cerrar ventana">
                                    <i aria-hidden="true" class="iconsax-essential-close-square"></i>
                                </button>
                                <div class="body">
                                    <div class="modal-title-small">
                                        <h1>
                                            Ingresa el NIP de seguridad de tu cuenta Pagando
                                        </h1>
                                        <p>
                                            Esto nos ayuda a verificar tu identidad y confirmar la seguridad de tu cuenta.
                                        </p>
                                    </div>
                                    <CodeInput></CodeInput>
                                    <CodeInput :hasError="true" showWarning
                                        warningText="El NIP que has ingresado es incorrecto. Inténtalo de nuevo."></CodeInput>
                                    <div class="btn-container">
                                        <button class="button button-ghost button-text_info">Cancelar</button>
                                        <button class="button button-filled button-primary disabled">Confirmar</button>
                                    </div>
                                </div>
                            </b-modal>

                            <!-- MODAL LOADING -->
                            <b-modal
                                id="modal-loading"
                                size=""
                                hide-header
                                hide-footer
                                modal-class="modal-default code-modal">
                                <button class="close-modal"
                                        @click="$bvModal.hide('modal-loading')"
                                        aria-label="Boton cerrar ventana"
                                        name="Boton cerrar ventana">
                                    <i aria-hidden="true" class="iconsax-essential-close-square"></i>
                                </button>
                                <div class="body">
                                    <div class="modal-title-small">
                                        <h1>
                                            Ingresa el NIP de seguridad de tu cuenta Pagando
                                        </h1>
                                        <p>
                                            Esto nos ayuda a verificar tu identidad y confirmar la seguridad de tu cuenta.
                                        </p>
                                    </div>
                                    <CodeInput></CodeInput>
                                    <div class="btn-container">
                                        <button class="button button-ghost button-text_info">Cancelar</button>
                                        <button class="button button-filled button-primary disabled">Confirmar</button>
                                    </div>
                                    <LoadingBar clazz="center-floating" loadingText="Validando nip"></LoadingBar>
                                </div>
                            </b-modal>

                            <br /><br />
                            <br /><br />

                            <h3 class="f-18 body-font-bold c-accent_2">Modal Alert</h3>
                            <p>
                                <strong>Params:</strong>
                                <br /><br />
                                <strong>modalId:</strong> ID for modal, should be: 'modal-success',
                                'modal-danger', 'modal-info', 'modal-warning' (String).
                                <br />
                                <strong>clazz:</strong> Extra class for component 'content-alert'
                                (String).
                                <br />
                                <strong>title:</strong> Text title (String).
                                <br />
                                <strong>text:</strong> Text for subtitle (String).
                                <br />
                                <strong>buttonCancel:</strong> Text for button ghost, default text:
                                'Cancelar' (String).
                                <br />
                                <strong>buttonType:</strong> Class for button filled (String).
                                <br />
                                <strong>buttonText:</strong> Text for button filled (String).
                                <br />
                                <strong>imgUrl:</strong> Text URL for modal (String).
                                <br />
                            </p>
                            <div class="horizontal-center">
                                <button
                                    class="button xs button-filled button-error m-r-10"
                                    @click="$bvModal.show('modal-alert')"
                                    type="button"
                                >
                                    Modal <strong>Alert</strong>
                                </button>
                            </div>

                            <!-- Modal alert -->
                            <ModalAlert
                                closeCross
                                modalId="modal-alert"
                                buttonType="button-primary"
                                :imgUrl="imageModalTrash"
                                title="¿Deseas cancelar tu pago?"
                                text="Al cancelar tu pago, automáticamente se cerrara la sesión de tu cuenta Pagando por seguridad.
                                Al finalizar el proceso, volverás al sitio web de origen."
                                buttonText="Cancelar Pago"
                            ></ModalAlert>
                        </CardContainer>
                    </div>

                    <!-- PanelPaymentMethod -->
                    <div class="col-12">
                        <CardContainer
                            title="Panel Payment Method"
                            subtitle="Params:">
                            <p>
                                <strong>title:</strong> Title text
                                <br />
                                <strong>text:</strong> Description text
                                <br />
                                <strong>image:</strong> Image on the right
                            </p>
                            <PanelPaymentMethod
                            title="Pagar con tarjeta de débito o crédito"
                            text="Realiza tu pago con la seguridad de Pagando,
                            utilizando una tarjeta de débito, crédito o prepago."
                            :image="imageCards"
                            />
                            <PanelPaymentMethod
                            title="Pagar con cuenta Pagando"
                            text="Inicia sesión en tu cuenta Pagando y realiza
                            tu pago de forma segura con alguna de tus cuentas."
                            :image="pagandoLogo"
                            />
                            <PanelPaymentMethod
                            title="Pagar con transferencia"
                            text="Te proporcionaremos una CLABE y una referencia para que
                            realices una transferencia desde el banco de tu preferencia."
                            :image="spei"
                            />
                        </CardContainer>
                    </div>

                    <!-- PROFILE USER IMAGE -->
                    <div class="col-12">
                        <CardContainer
                            title="Profile User Image"
                            subtitle="Params:">
                            <p>
                                <strong>clazz:</strong> Extra class for component (String).
                                <br />
                                <strong>imgUser:</strong> To show user's image (Boolean).
                                <br />
                                <strong>imgUploaded:</strong> To show button 'Cambiar' (Boolean).
                                <br />
                                <strong>showInfoUser:</strong> To show user's info (Boolean).
                                <br />
                                <strong>userName:</strong> Text for user's name or email (String).
                                <br />
                            </p>
                            <UploadProfileImage
                                imgUser
                                imgUploaded
                                showInfoUser
                                clazz="horizontal-info xs user-verification-login"
                                userName="“La vida es mejor Pagando”"
                            ></UploadProfileImage>
                        </CardContainer>
                    </div>

                    <!-- Payment status -->
                    <div class="col-12">
                        <CardContainer
                            title="Payment status"
                            subtitle="Params:">
                            <p>
                                <strong>clazz:</strong> Extra class for component (String).
                                <br />
                                <strong>title:</strong> (String)
                                <br />
                                <strong>text:</strong> (String)
                                <br />
                            </p>
                            <PaymentStatusMessage
                            titleClass="c-primary"
                            validation
                            title="Validación finalizada"
                            >
                            La validación ha finalizado, a continuación procesaremos tu pago,
                            <strong class="poppins-font-bold">por favor no cierres ni recargues la página.</strong>
                            </PaymentStatusMessage>
                        </CardContainer>
                    </div>

                    <!-- PROMO PREVIEW -->
                    <div class="col-12">
                        <CardContainer
                            title="Promotion preview"
                            subtitle="Params:">
                            <p>
                                <strong>number:</strong> The number of months.
                                <br />
                                <strong>typeOfPromo:</strong> 'Meses sin intereses' or 'Pagos fijos'.
                                <br />
                                <strong>amountMoney:</strong> the amount of money.
                                <br />
                                <strong>total:</strong> The total of the debt.
                                <br />
                                <strong>noPromo:</strong> Boolean.
                            </p>
                            <promoPreview
                            number="6"
                            typeOfPromo="Meses sin intereses"
                            amountMoney="218.00"
                            total="1,232.80"/>
                            <br /><br /><br />
                            <promoPreview
                            noPromo/>
                        </CardContainer>
                    </div>

                    <!-- TITLES -->
                    <div class="col-12">
                        <CardContainer
                            title="Titles">
                            <div class="row">
                                <div class="col-12">
                                    <div class="principal-title">
                                        <h1>
                                            Paga fácil con Pagando Check
                                        </h1>
                                        <p>
                                            Al realizar tus pagos utilizando Pagando Check,
                                            tienes una <strong>protección total de tus datos personales
                                            y los datos de tu tarjeta.</strong>
                                        </p>
                                    </div>
                                    <div class="regular-title">
                                        <h1>
                                            Formas de pago
                                        </h1>
                                        <p>
                                            Aqui encontraras tus <strong>cuentas Pagando</strong>,
                                            selecciona con cual cuenta deseas realizar el pago.
                                        </p>
                                    </div>
                                    <div class="modal-title-big">
                                        <h1>
                                            Pagar con Pagando
                                        </h1>
                                        <p>
                                            Ingresa tus datos para iniciar sesión en tu cuenta Pagando
                                            y gozar de los beneficios de tu cuenta y protección de tus datos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </CardContainer>
                    </div>

                    <!-- TOAST ALERTS -->
                    <div class="col-12">
                        <CardContainer
                            title="Toast Alerts"
                            subtitle="Params:">
                            <a
                                href="https://artemsky.github.io/vue-snotify/"
                                class="f-14 f-bold c-error d-block m-b-30"
                            ><i
                                    aria-hidden="true"
                                    class=""
                                ></i> See Documentation </a>
                            <ToastSnotify />
                        </CardContainer>
                    </div>

                    <!-- TOOLTIPS  -->
                    <div class="col-12">
                        <CardContainer
                            title="Tooltips / Popovers"
                            subtitle="Params:">
                                <p>
                                    <strong>id:</strong> Should be the same as the 'target' (String).
                                    <br>
                                    <strong>target:</strong> id from button (String).
                                    <br>
                                    <strong>placement:</strong> Where to place the tooltip 'top, bottom, left, right'
                                    (String).
                                    <br>
                                    <strong>custom-class:</strong> To add a custom class (String).
                                    <br>
                                    <strong>To change tooltip to popover just have to change 'b-tooltip' to
                                        'b-popover.</strong>
                                    <br>
                                </p>
                                <a
                                    href="https://bootstrap-vue.js.org/docs/components/tooltip"
                                    class="f-14 f-bold c-error d-block m-b-30">
                                    <i
                                        aria-hidden="true"
                                        class=""
                                    ></i> See Documentation </a>
                                <div>
                                    <div class="d-flex">
                                        <div class="w-50 p-10">
                                            <button
                                                id="button-tooltip"
                                                class="button xs button-filled button-primary m-r-10"
                                            >
                                                Tooltip
                                            </button>
                                            <b-tooltip
                                                target="button-tooltip"
                                                placement="right"
                                                custom-class="white-tooltip"
                                            >
                                                <p class="m-b-0">
                                                    ¿Cuáles son las promociones y bancos
                                                    disponibles que participan en esta
                                                    modalidad de pago?
                                                    <br>
                                                    <a href="#">Ir a preguntas frecuentes</a>
                                                </p>
                                            </b-tooltip>
                                        </div>
                                    </div>
                                </div>
                        </CardContainer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import BasicInput from '@/components/BasicInput.vue'
import PasswordInput from '@/components/PasswordInput.vue'
import LoadingBar from '@/components/LoadingBar.vue'
import RadioInput from '@/components/RadioInput.vue'
import BasicCheckbox from '@/components/BasicCheckbox.vue'
import BasicSelect from '@/components/BasicSelect.vue'
import ToastSnotify from '@/components/ToastSnotify.vue'
import Multiselect from 'vue-multiselect'
import ModalAlert from '@/components/ModalAlert.vue'
import CodeInput from '@/components/CodeInput.vue'
import UploadProfileImage from '@/components/UploadProfileImage.vue'
import imageModalTrash from '@/assets/images/Modals/card-cancel.svg'
import CardContainer from '@/components/CardContainer.vue'
import TopbarCustom from '@/components/Topbar.vue'
import BusinessInfo from '@/components/BusinessInfo.vue'
import PromoPreview from '@/components/PromoPreview.vue'
import CardDetailPayment from '@/components/CardDetailPayment.vue'
import PanelPaymentMethod from '@/components/PanelPaymentMethod.vue'
import PanelCheckbox from '@/components/PanelCheckbox.vue'
import ReadOnlyInfo from '@/components/ReadOnlyInfo.vue'
import PaymentStatusMessage from '@/components/PaymentStatusMessage.vue'
import Logo from '@/assets/images/Default/testing/monique.jpg';
import imageCards from '@/assets/images/Logos/logos-payments.svg';
import pagandoLogo from '@/assets/images/Logos/logo-pagando.svg';
import spei from '@/assets/images/Logos/logo-spei.svg';

export default {
    name: "UxComponents",
    components: {
        BasicInput,
        PasswordInput,
        CodeInput,
        LoadingBar,
        RadioInput,
        BasicCheckbox,
        BasicSelect,
        ToastSnotify,
        Multiselect,
        ModalAlert,
        UploadProfileImage,
        CardContainer,
        TopbarCustom,
        BusinessInfo,
        PromoPreview,
        CardDetailPayment,
        PanelPaymentMethod,
        PanelCheckbox,
        ReadOnlyInfo,
        PaymentStatusMessage
    },
    data () {
        return {
            Logo: Logo,
            imageModalTrash: imageModalTrash,
            imageCards: imageCards,
            pagandoLogo: pagandoLogo,
            spei: spei,
            valueGenero: [],
            optionsGenero: [
                { name: "Masculino" },
                { name: "Femenino" }
            ],
            valueTags: [],
            optionsTags: [
                { name: 'Flores' },
                { name: 'Arreglos' },
                { name: 'Comida china' },
                { name: 'Ropa de invierno' },
                { name: 'Accesorios para auto' },
                { name: 'Telefonía' }
            ],
            valuePais: [],
            optionsPais: [
                { name: "Mexico" },
                { name: "Estados Unidos" },
            ],
            valueEstado: [],
            optionsEstado: [
                { name: "Chihuahua" },
                { name: "Sinaloa" }
            ],
            valuePromo: [],
            optionsPromo: [
                {
                    typePromo: 'Meses sin intereses',
                    promo: [
                        { paymentPromo: '3 Meses sin intereses', paymentMoney: '3 pagos de $563.60', total: '1,690.80' },
                        { paymentPromo: '6 Meses sin intereses', paymentMoney: '6 pagos de $563.60', total: '1,690.80' },
                        { paymentPromo: '9 Meses sin intereses', paymentMoney: '9 pagos de $563.60', total: '1,690.80' },
                    ]
                },
                {
                    typePromo: 'Pagos fijos',
                    promo: [
                        { paymentPromo: '3 Pagos fijos', paymentMoney: '3 pagos de $591.78 / Comisión 5%', total: '1,690.80' },
                        { paymentPromo: '6 Pagos fijo', paymentMoney: '6 pagos de $309.98 / Comisión 10%', total: '1,690.80' },
                    ]
                },
            ]
        }
    },
    methods: {}
};
</script>
