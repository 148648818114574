<template>
    <div class="floating-text" id="floatingBottomText">
        {{text}} <strong>{{strong}}</strong>
    </div>
</template>

<script>
    export default {
        name: 'BottomFloatingNote',
        props: {
            text: String,
            strong: String
        },
        methods: {
            // bottomNote() {
            //     var windowHeight = window.innerHeight;
            //     var HeightContainer = document.getElementById('main-view').offsetHeight;
            //     if (HeightContainer > windowHeight) {
            //         document.getElementById('floatingBottomText').classList.add("absolute");
            //     } else {
            //         document.getElementById('floatingBottomText').classList.remove("absolute");
            //     }
            // }
        },
        mounted () {
            // this.bottomNote();
            // window.addEventListener('resize', this.bottomNote);
            // window.addEventListener('scroll', this.bottomNote);
        },
    }
</script>
