<template>
    <div class="radio-input" :class="clazz">
        <input ref="input" :class="inputClass" type="radio" :name="name" v-bind:id="id" aria-label="radio"
            @click="selected" v-model="radioInputValue" :disabled="disabled">
        <span class="role" :class="spanClazz" v-if="!isHiddenText" v-bind:for="labelFor">{{text}}</span>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'RadioInput',
    data() {
        return {
            radioInputValue: false
        }
    },
    props: {
        id: String,
        name: { type: String , default: 'role'},
        labelFor: String,
        text: String,
        clazz: String,
        inputClass: String,
        isHiddenText: {
            type: Boolean, default: false
        },
        currentValue: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
        spanClazz: String
    },
    methods: {
        selected(event){
            if(event) {
                this.$emit('selected', event.target.value)
            }
        },
        reloadInternalValue(value){
            this.radioInputValue = value;
            if (this.radioInputValue) {
                this.$refs.input.checked = true;
            }
            this.$forceUpdate();
        },
        updateValue(){
            this.radioInputValue = this.currentValue;
            if (this.radioInputValue) {
                this.$refs.input.checked = true;
            }
        }
    },
    watch: {
        currentValue(){
            this.updateValue();
        }
    },
    mounted(){
        this.updateValue();
    }
}
</script>
