<template>
    <div class="panel-payment-method" :class="clazz" @click="goTo()">
        <div class="info">
            <div class="title">
                {{title}}
            </div>
            <p>
                {{text}}
            </p>
        </div>
        <div class="img-container">
            <img class="img-fluid" :src="image" :class="imgClass"
            width="210" height="29" alt="Logo de metodo de pago" />
        </div>
        <i aria-hidden="true" class="iconsax-arrow-direction-right"></i>
    </div>
</template>

<script>
    export default {
        name: "PanelPaymentMethod",
        props: {
            clazz: String,
            title: String,
            text: String,
            image: String,
            imgClass: String,
        },
        methods: {
            goTo() {
                this.$emit('click');
            }
        }
    };
</script>
