export const STORE = 'SESSION';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_IS_OWNER = 'SET_IS_OWNER';
export const GET_IS_OWNER = 'GET_IS_OWNER';
export const SET_TOKEN_EXPIRE_DATE = 'SET_TOKEN_EXPIRE_DATE';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_USER = 'GET_USER';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_TOKEN_EXPIRE_DATE = 'GET_TOKEN_EXPIRE_DATE';
export const GET_USER_OBJECT = 'GET_USER_OBJECT';
export const SET_AVIABLE_ORGANIZATIONS_PROFILES = 'SET_AVIABLE_ORGANIZATIONS_PROFILES';
export const CHANGE_PROFILE_JWT = 'CHANGE_PROFILE_JWT';
export const SET_IS_ADMIN = 'SET_IS_ADMIN';
export const GET_IS_ADMIN = 'GET_IS_ADMIN';
export const SET_PROFILE_ADMIN = 'SET_PROFILE_ADMIN';
export const GET_PROFILE_ADMIN = 'GET_PROFILE_ADMIN';
export const CHANGE_OTHER_PROFILE_JWT = 'CHANGE_OTHER_PROFILE_JWT';
export const CLEAR_STORE = 'CLEAR_STORE';
export const GET_CURRENT_LOCATION = 'GET_CURRENT_LOCATION';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
