<template>
    <div
        :id="id"
        class="form-element-group"
        :class="`${clazz} ${errorClass}`"
    >
        <input
            :id="inputId"
            :type="inputType"
            :maxlength="maxLength"
            class="form-element-control"
            :class="[badge ? 'inputBadge': inputClazz]"
            :placeholder="inputPlaceholder"
            :value="value || inputValue"
            :aria-label="name"
            @input="onBasicInput"
            :disabled="disabled"
            :name="name"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="onEnterKey"
        />
        <button
            class="extra-input-button"
            :class="extraButtonClazz"
            v-if="inputClazz"
            @click="buttonAction"
            type="button"
            :name="nameButton"
            :aria-label="nameButton"
        >
            <i
                class="input-icon banana"
                :class="iconInput"
                aria-hidden="true"
            ></i>
            {{buttonText}}
        </button>
        <BadgeCustom
            v-if="badge"
            :type="badgeType"
            :text="badgeText"
        ></BadgeCustom>

        <div class="input-label">
            <label
                class=""
                :for="labelFor"
            >{{label}} <span v-if="required">*</span></label>
            <div
                v-if="help"
                :id="idHelpTooltip"
                class="help-info"
            >
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i aria-hidden="true" :class="warningIcon || warningIconComputed"></i>
            {{warningText || warningTextComputed}}
        </small>

        <slot name="windowFloating"></slot>
    </div>
</template>

<script>
import { debounce } from "@/api/utils.js";
import BadgeCustom from '@/components/Badge.vue';

export default {
    name: 'BasicInput',
    components: {
        BadgeCustom
    },
    props: {
        id: String,
        help: { type: Boolean, default: false },
        idHelpTooltip: String,
        required: { type: Boolean, default: false },
        label: String,
        helpText: String,
        maxLength: Number,
        warningText: String,
        inputPlaceholder: String,
        helpIcon: String,
        warningIcon: String,
        labelFor: String,
        inputId: String,
        clazz: String,
        inputType: String,
        disabled: { type: Boolean, default: false },
        inputValue: { type: String, default: null },
        hasBlur: { type: Boolean, default: false },
        inputClazz: String,
        iconInput: String,
        name: {
            type: String,
            default: ""
        },
        value: { type: [String, Number], default: null },
        badge: { type: Boolean, default: false },
        badgeType: String,
        badgeText: String,
        validations: {},
        validationsNamespace: {
            type: String,
            default: "general.validations"
        },
        idForm: String,
        buttonText: String,
        extraButtonClazz: String,
        nameButton: String,
    },
    methods: {
        onBasicInput (event) {
            this.$emit('basicInput', event.target.value);
            this.$emit('input', event.target.value);
            if (this.validations) {
                debounce(() => {
                    this.validations.$touch();
                });
            }
        },
        onEnterKey(){
            this.$emit('enterKey');
        },
        buttonAction(){
            this.$emit('buttonAction');
        },
        onFocus () {
            this.$emit('focus');
        },
        onBlur () {
            this.$emit('blur');
        }
    },
    computed: {
        errorClass () {
            return this.validations && this.validations.$error ? 'has-error' : '';
        },
        warningTextComputed () {
            if (this.validations) {
                const errorKey = Object
                    .keys(this.validations)
                    .find(k => Object.hasOwn(this.validations, k) && k.indexOf('$') === -1 && !this.validations[k]);
                if (errorKey) {
                    const params = this.validations.$params[errorKey];
                    return this.$t(`${this.validationsNamespace}${this.validationsNamespace ? '.' : ''}${errorKey}`, params);
                }
                return null;
            }
            return null;
        },
        warningIconComputed () {
            if (this.validations) {
                const errorKey = Object
                    .keys(this.validations)
                    .find(k => Object.hasOwn(this.validations, k) && k.indexOf('$') === -1 && !this.validations[k]);
                return errorKey ? 'banana banana-warning-circle1' : null;
            }
            return null;

        }
    }
}
</script>
