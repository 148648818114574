<template>
    <b-modal :id="modalId" :ref="customRef ? customRef : 'modalAlert'" hide-header hide-footer :no-close-on-esc="closeEsc" :no-close-on-backdrop="closeBackdrop">
        <button v-if="!closeCross" class="close-modal" @click="hideModal()" aria-label="Boton cerrar ventana" name="Boton cerrar ventana">
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </button>
        <div class="content-alert" :class="clazz">
            <img class="img-fluid" :src="imgUrl" alt="Imagen de modal alerta" />
            <div class="title">{{title}}</div>
            <p>{{text}}</p>

            <slot></slot>

            <div class="buttons">
                <button :id="idCan" v-if="!noCancelButton" class="button button-stroke" :class="buttonType" @click="cancel()">
                    {{buttonCancel}}
                </button>
                <button :id="idAct" v-if="!localLoading" class="button button-filled" @click="submit" :class="buttonType">
                    {{buttonText}}
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import {EventBus} from "@/main";
    import Imagen from '@/assets/images/Modals/card-cancel.svg'

    export default {
        name: 'ModalAlert',
        props: {
            modalId: String,
            clazz: String,
            title: String,
            text: String,
            buttonCancel: {type: String, default: 'Volver'},
            buttonType: String,
            buttonText: String,
            imgUrl: {
                type: String,
                default: Imagen
            },
            customRef: String,
            idCan: String,
            idAct: String,
            loading: {
                type: Boolean,
                default: false
            },
            withAutomaticLoad: {
                type: Boolean,
                default: false
            },
            closeCross: {
                type: Boolean,
                default: false
            },
            closeEsc: {
                type: Boolean,
                default: false
            },
            closeBackdrop: {
                type: Boolean,
                default: false
            },
            noCancelButton: {
                type: Boolean,
                defautl: false
            }
        },
        data() {
            return {
                defaultUrl: Imagen,
                localLoading: false,
            }
        },
        methods: {
            hideModal() {
                this.$refs[this.customRef ? this.customRef : 'modalAlert'].hide();
                if(this.withAutomaticLoad){
                    this.localLoading = false;
                }
            },
            openModal() {
                this.$refs[this.customRef ? this.customRef : 'modalAlert'].show();
            },
            cancel() {
                this.$refs.modalAlert.hide();
                this.$emit('cancel')
            },
            changeIsLoading() {
                this.localLoading = !this.localLoading;
            },
            submit() {
                if(this.withAutomaticLoad){
                    this.localLoading = true;
                }
                this.$emit('submit')
            }
        },
        created(){
            this.localLoading = !!this.loading;
            EventBus.$on(`CLOSE_MODAL_${this.modalId}`, ()=>{
                this.$refs[this.customRef ? this.customRef : 'modalAlert'].hide();
            })
        }
    }
</script>
