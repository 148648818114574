/* eslint-disable */
/* --------------Guía de contribución al i18n---------------
La estructura general es simple:
{{clasificación}}.{{sub-clasificación}}[.{{llave-del-mensaje}}][.{{llave-del-mensaje}}]

Reglas generales:
    - No se permiten mayúsculas.
    - Todos los segmentos de la llave se separan con punto (.)
    - No se permiten mensajes sin clasificación:
        ej.
            x incorrecto:
                "nombre": "Nombre"
                "email": "Email"
            ✓ correcto:
                "usuarios.nombre": "Nombre"
                "usuarios.email": "Email"

    - Si un segmento tiene más de una palabra, deben separarse con guión (-).
    - No duplicar mensajes para plurales. Se debe usar la pluralización de vue-i18n (http://kazupon.github.io/vue-i18n/guide/pluralization.html)
        ej.
            x incorrecto:
                "usuarios.usuario": "Usuario"
                "usuarios.usuarios": "Usuarios"

            ✓ correcto:
                "usuarios.usuario": "Usuario | Usuarios"

    - No se permiten acentos, así que de preferencia utiliza "hacks" para palabras que no suenen bien:
        ej. año => anio

    - Si se usa inglés, por favor verifica que lo que escribas es correcto
        ej.
            x incorrecto:
                clasification
            ✓ correcto:
                classification (doble 's')

            x incorrecto:
                wizzard
            ✓ correcto:
                wizard (una sola 'z')

    - Si ya existe una internacionalización que corresponde a lo que deseas usar, verifica que tenga sentido reutilizarla.
        ej.
            Si quieres colocar "Email" para usuario.email y ya existe la siguiente internacionalización:
                "proveedor.email": "Email"

            Es tentador usarla, ¿pero que pasa si en un futuro se cambia el valor de la siguiente manera?
                "proveedor.email": "Email del Proveedor"

            Este uso es incorrecto, por reutilizar el i18n de una llave existente, pero que no corresponde a la misma propiedad,

            Aunque tenga el mismo valor, conceptualmente la i18n puede ser distinta

    - Mantén las clasificaciones juntas y ordenadas. Es tentador agregar tus i18ns al final, pero ayuda a mantener el orden de la aplicación.
        ej.
            x incorrecto:
                usuarios.foo.1
                proveedores.foo
                usuarios.bar
                usuarios.foo.2
                comparar.baz
                usuarios.baz
                proveedores.bar
                usuarios.foo.3
                comparar.foo
                comparar.bar


            ✓ correcto:
                usuarios.foo.1
                usuarios.foo.2
                usuarios.foo.3
                usuarios.bar
                usuarios.baz
                proveedores.foo
                proveedores.bar
                comparar.foo
                comparar.bar
                comparar.baz

¿Inglés o español?
    Se prefiere español para términos de los procesos y los datos de la plataforma.
        Ej. proveedor, calculo, tipo-rfc

    Se prefiere inglés para términos de sistemas.
        Ej. error, info, validations, warning, required

    Si tienes dudas, o ambos lenguajes son viables, puedes basarte en un uso similar que ya exista, o confiar en tu corazón.


--------Clasificaciones (procesos)-------
general
usuarios
proveedores
unidades-administrativas
recursos
contratos
calculos
comparar

--------Subclasificaciones (partes de los procesos anteriores)-------
error
success
info
warning
[nombre-de-propiedad]
[nombre-de-accion] (ejemplos: "save", "delete")

--------- Sub-sub-clasificaciones de [nombre-de-propiedad] ---------
validations
status
[sub-propiedades-de-la-propiedad]

--------Ejemplos-------
"general.app-name": "Monitor Karewa"
"general.copyright": "Black Labs 2020"
"general.error.cargar-registros": "No fue posible cargar los registros. Por favor recarga la página e intenta nuevamente."
"general.info.loading": "Cargando..."

"usuarios.usuario": "Usuario"
"usuarios.email": "Email"
"usuarios.email.description": "El email que utilizará el usuario para acceder a la plataforma."
"usuarios.email.placeholder": "Introduce el email del usuario."
"usuarios.email.validations.required": "El email del Usuario es requerido."
"usuarios.email.validations.invalid": "Por favor introduce un email válido para el Usuario."
"usuarios.email.status": "Estatus del email"
"usuarios.email.status.pendiente-validacion": "Pendiente de validar"
"usuarios.email.status.validado": "Validado"

"proveedores.error.duplicado": "El Proveedor ya se encuentra registrado."
"proveedores.warning.tipo-rfc-inconsistente": "El RFC registrado para este Proveedor corresponde a una persona moral, pero el Proveedor es persona física."
"proveedores.rfc": "RFC del Proveedor"
"proveedores.rfc.validations.required": "El RFC del Proveedor es requerido."
 */

export default {
    "payment-process": {
        "loading-text": {
            "process-payment": "Tu pago está siendo procesado.",
            "minutes-message": "Esto puede tardar unos minutos...",
        },
        "loading-text-3ds": {
            "process-payment": "Tu pago está siendo procesado, estamos analizando las medidas de seguridad para que tu compra sea más segura.",
            "minutes-message": "Esto puede tardar unos minutos…",
            "dont-close": "IMPORTANTE: No cierres esta ventana ni presiones el botón “Atras” o “Actualizar” de tu navegador, para que tu pago pueda ser concluido con éxito.",
        },
    },
    "general": {
        "changePaymentMethod": "Cambiar forma de pago",
        "help": "Ayuda",
        "loading": "Cargando...",
        "goToPagandoCheck": "Ir a Pagando Check",
        "closeSession": "Cerrar Sesión",
        "actions": {
            "add": "Agregar",
            "change": "Cambiar",
            "success": {
                "title": "Éxito",
                "text": "La acción se ha ejecutado exitosamente."
            },
            "confirm": {
                "title": "Confirmar"
            },
            "error": {
                "title": "Error",
                "text": "Hubo un problema al ejecutar la acción"
            },
            "cancel": {
                "title": "Cancelar"
            },
            "back": {
                "title": "Regresar"
            },
            "continue": {
                "title": "Continuar"
            }
        },
        "clickable-link": {
            "mas-servicios": "Ver más servicios →",
        },
        "validations": {
            "required": "Este campo es requerido",
            "minLength": "{min} caracteres mínimo",
            "maxLength": "{max} caracteres máximo",
            "numeric": "Sólo valores numéricos",
            "expirationDateValidation": "Formato inválido",
            "cardNumberValid": "El número es inválido",
            "cvvValid": "El cvv es inválido",
            "expirationValid": "La fecha de expiración es inválida",
            "additionalNotesRequired": "Requerido",
            "email": "Ingrese un correo electrónico válido."
        }
    },
    "sign-in": {
        "login-incorrect-data": "Tus datos de usuario o contraseña son incorrectos. Por favor, verificalos y vuelve a intentarlo." ,
        "login-blocked": "Has excedido el número de intentos para iniciar sesión. Por favor, inténtalo de nuevo en 30 minutos.",
    },
    "externalPayment": {
        "baseExternal": {
            "errors" : {
                "get-info" : "Ocurrió un error tratando de cargar la orden de pago."
            }
        },
        "login": {
            "warnings" : {
                "test-social": "Función no disponible en modo test",
                "no-test-email": "El correo electrónico no coincide con alguno de los de prueba",
            }
        },
        "payment": {
            "errors" : {
                "payment": "Hubo un error tratando de procesar el pago, por favor inténtelo de nuevo más tarde, o con una tarjeta diferente",
                "api-expired": "La llave de acceso a expirado",
                "no-user": "No hay usuario para el id entregado",
                "email": "el correo electrónico es incorrecto",
                "ommited-user-data": "Existe un error en el formato de los datos de contacto",
                "user-not-belong-to-organization": "No es posible asociar el usuario con la organización",
                "parameter-limit": "Limite de parametros excedido",
                "parameter-limit-size": "Número de parametros excedido",
                "authorization-header": "No se encontró la autorización",
                "ip": "IP valida es necesaria",
                "credentials": "Las credenciales proporcionadas son invalidas",
                "card-invalid": "La tarjeta proporcionada es invalida, intenta más tarde o usa una tarjeta diferente",
                "security-code": "El número de seguridad de la tarjeta proporcionada es invalida, intenta más tarde o usa una tarjeta diferente",
                "card-owner": "El nombre del propietario de la tarjeta no coincide con el usuario",
                "card-assign": "No se puede asignar la tarjeta al usuario",
                "card-info": "La información de la tarjeta no es valida",
                "card-address": "Falta información de la dirección de la tarjeta",
                "payment-settings": "No se encontraron configuraciones de pago, contacta al administrador",
                "user-method": "El metodo de pago no se encuentra asociado al usuario",
                "amount": "No se ha especificado el monto a pagar",
                "declined": "El pago fue declinado, intenta más tarde o usa una tarjeta diferente",
                "anti-fraud": "No se encontró un metodo de anti fraude",
                "anti-fraud-token": "Token de anti fraude no valido, contacta al administrador",
                "fraud": "La transacción fue declinada por posible fraude, intenta más tarde o usa una tarjeta diferente",
                "route": "La ruta de configuración para el checkout no fue definida, contacta al administrador",
                "order": "No existe una orden para el id proporcionado",
                "default": "Ocurrió un error inesperado, por favor intenta más tarde",
                "card": "No se logró registrar el método de pago, por favor intentelo de nueve más tarde, o con una tarjeta diferente",
                "user": "No se logró validar el usuario, por favor intentalo de nuevo",
                "missing-card-owner": "El campo Titular de la tarjeta no se encuentra o es incorrecto",
                "missing-contact-name": "El campo Nombre del Usuario no se encuentra o es incorrecto",
                "missing-contact-last-name": "El campo Apellido del Usuario no se encuentra o es incorrecto",
                "missing-card-number": "El campo Número de tarjeta no se encuentra o es incorrecto",
                "missing-expiration-date": "El campo Fecha de expiración no se encuentra o es incorrecto",
                "missing-cvv": "El campo CVV no se encuentra o es incorrecto",
                "missing-country": "Falta seleccionar el campo País",
                "missing-birthday": "Falta seleccionar el campo Fecha de nacimiento",
                "missing-street": "Falta ingresar el campo Dirección",
                "missing-postal-code": "Falta ingresar el campo Código postal",
                "missing-ext-no": "El campo Número exterior no se encuentra o es incorrecto",
                "missing-int-no": "El campo Número interior es incorrecto",
                "missing-district": "Falta ingresar el campo Colonia",
                "missing-state": "Falta ingresar el campo Estado",
                "missing-city": "Falta ingresar el campo Ciudad",
                "missing-email": "El campo Email no se encuentra o es incorrecto",
                "missing-phone": "El campo Teléfono no se encuentra o es incorrecto",
                "no-users-test": "Es necesario generar los usuarios de prueba y realizar pruebas con ellos antes de proceder a realizar pagos",
                "error-delete": "No fue posible eliminar la tarjeta"
            }
        }
    },
    "paymentLink": {
        "success": "Tu pago ha sido procesado",
        "successfully": "exitosamente",
        "error": "al procesar el check link",
        "anError": "Ocurrió un error",
        "errorProccess": "Ocurrió un error al procesar check link"
    },
    "welcome": {
        "title": "Paga fácil con Pagando Check",
        "subtitle": "Al realizar tus pagos utilizando Pagando Check, tienes una <strong>protección total de tus datos personales y los datos de tu tarjeta.</strong>",
        "payCreditDebit": {
            "title": "Pagar con tarjeta de débito o crédito",
            "subtitle": "Realiza tu pago con la seguridad de Pagando, utilizando una tarjeta de débito, crédito o prepago."
        },
        "payPagandoAccount": {
            "title": "Pagar con cuenta Pagando",
            "subtitle": "Inicia sesión en tu cuenta Pagando y realiza tu pago de forma segura con alguna de tus cuentas."
        },
        "payTransfer": {
            "title": "Pagar con transferencia",
            "subtitle": "Te proporcionaremos una CLABE y una referencia para que realices una transferencia desde el banco de tu preferencia."
        },
        "goBack": "Volver a la tienda",
        "login": {
            "info": "Abre una cuenta a tu medida sin acudir a una sucursal. Realiza tus <strong>compras y pagos de servicios por internet</strong> o en tus negocios favoritos con tu <strong>tarjeta Mastercard.</strong>",
            "title": "Pagar con Pagando",
            "subtitle": "Ingresa tus datos para iniciar sesión en tu cuenta Pagando y gozar de los beneficios de tu cuenta y protección de tus datos.",
            "email": {
                "title": "Correo electrónico",
                "placeholder": "Introduce tu correo electrónico"
            },
            "password": {
                "title": "Contraseña",
                "placeholder": "Ingresar contraseña"
            },
            "button": "Iniciar sesión",
            "forgotUser": "¿Olvidaste tu usuario?",
            "forgotPass": "¿Olvidaste tu contraseña?",
            "noRegister": "¿Todavía no estás registrado?",
            "createAccount": "Crea una cuenta"
        }
    },
    "businessInfo": {
        "soldBy": "Vendido por:",
        "phone": "Teléfono:",
        "email": "Correo:",
        "payDetails": "Detalle de tu pago",
        "contact": "Contacto:",
        "total": "TOTAL"
    },
    "locale": {
        "es": "Español",
        "en": "Ingles"
    },
    "footer": {
        "text": "Pagando Copyright 2021, una marca registrada de"
    },
    "payCreditDebit": {
        "step1": {
            "title": "PASO 01",
            "subtitle": "Datos personales",
            "name": {
                "title": "Nombre",
                "placeholder": "Ingresa tu nombre"
            },
            "lastName": {
                "title": "Primer apellido",
                "placeholder": "Ingresa tu primer apellido"
            },
            "secondLastName": {
                "title": "Segundo apellido",
                "placeholder": "Ingresa tu segundo apellido"
            },
            "email": {
                "title": "Correo electrónico",
                "placeholder": "Ingresa tu dirección de correo electrónico",
                "errorFormat": "El formato debe ser como el siguiente: mail@mail.com"
            },
            "birthdate": {
                "title": "Fecha de nacimiento",
                "placeholder": "DD / MM / AAAA",
            },
            "phone": {
                "title": "Número de teléfono",
            },
        },
        "step2": {
            "title": "PASO 02",
            "subtitle": "Dirección",
            "address": {
                "title": "Dirección",
                "placeholder": "Ingresa la calle o avenida"
            },
            "country": {
                "title": "País",
                "placeholder": "Selecciona el país"
            },
            "state": {
                "title": "Estado",
                "loading": "Cargando estados...",
                "placeholderDropdown": "Selecciona el estado",
                "placeholder": "Ingresa el estado"
            },
            "city": {
                "title": "Ciudad",
                "placeholder": "Ingresa la ciudad"
            },
            "district": {
                "title": "Colonia",
                "placeholder": "Ingresa la colonia"
            },
            "noExt": {
                "title": "No. Exterior",
                "placeholder": "0",
                "error": "El valor debe de ser numérico"
            },
            "noInt": {
                "title": "No. Interior",
                "placeholder": "0",
                "error": "El valor debe de ser numérico"
            },
            "zipCode": {
                "title": "Código Postal",
                "placeholder": "Ingresa el código postal"
            }
        },
        "step3": {
            "title": "PASO 03",
            "subtitle": "Método de pago",
            "cardsAccepted": "Tarjetas aceptadas",
            "cardName": {
                "title": "Nombre del titular de la tarjeta",
                "placeholder": "Nombre completo",
                "error": "Error en el formato del campo Titular de la tarjeta"
            },
            "cardNumber": {
                "title": "Número de la tarjeta"
            },
            "expDate": {
                "title": "Fecha de vencimiento"
            },
            "cvv": {
                "title": "CVV/CVC",
                "placeholder": "Código de seguridad",
                "error": "El valor debe de ser numérico"
            },
            "searchPromos": "Buscando promociones disponibles...",
            "selectPromo": "Selecciona una promoción",
            "availablePromoTitle": "Promociones disponibles",
            "totalToPay": "Total a pagar",
            "availablePromos": "¿Cuáles son las promociones y bancos disponibles que participan en esta modalidad de pago?",
            "frequentlyQuestions": "Ir a preguntas frecuentes",
            "noPromo": "Sin promociones disponibles.",
            "promoMessage": "Esta compra cuenta con promociones para realizar el pago",
            "promoMessage2": "Mas información sobre las promociones.",
            "selectedPromo": "Promoción seleccionada",
            "typePromo": {
                "FIX_PAY": "Pagos Fijos",
                "MSI": "Meses sin intereses"
            },
            "paysOf": "pagos de"
        },
        "goBackPaymentMethods": "Volver a las opciones de pago",
        "cancelPayment": {
            "title": "¿Deseas cancelar tu pago?",
            "subtitle": "Al cancelar tu pago, por seguridad no se guardarán los datos que hayas ingresado. Volverás al sitio web de origen y tendrás que ingresar de nuevo los datos para pagar.",
            "button": "Cancelar Pago"
        }
    },
    "payPagandoAccount": {
        "paymentMethods": "Formas de pago",
        "pagandoAccount": "Aqui encontraras tus <strong>cuentas Pagando</strong>, selecciona con cual cuenta deseas realizar el pago.",
        "loading-cards": "Cargando formas de pago...",
        "noAccount": "No. cuenta:",
        "availableBalance": "Saldo disponible",
        "paymentDetail": "Detalle de tu pago",
        "goBackPaymentMethods": "Volver a las opciones de pago",
        "cancelPayment": {
            "title": "¿Deseas cancelar tu pago?",
            "subtitle": "Al cancelar tu pago, automáticamente se cerrara la sesión de tu cuenta Pagando por seguridad. Al finalizar el proceso, volverás al sitio web de origen.",
            "button": "Cancelar Pago"
        },
        "confirmationCode": {
            "title": "Ingresa código de confirmación",
            "subtitle": "Hemos enviado un mensaje SMS con tu código al número celular",
            "error": "El código que has ingresado no es válido. Inténtalo de nuevo.",
            "message": "El SMS puede tardar unos minutos en llegar, si no lo has recibido puedes presionar el siguiente link:"
        },
        "nip": {
            "title": "Ingresa el NIP de seguridad de tu cuenta Pagando",
            "subtitle": "Esto nos ayuda a verificar tu identidad y confirmar la seguridad de tu cuenta.",
            "validate": "Validando NIP..",
            "error": "El NIP que has ingresado es incorrecto. Inténtalo de nuevo."
        }
    },
    "cardDetailPayment": {
        "total": "Total",
        "pointsPagando": "Usar saldo de puntos Pagando disponibles",
        "points": "Puntos:",
        "valuePoint": "1 punto =",
        "totalToPay": "Total a pagar",
        "termsConditions1": "Acepto los",
        "termsConditions2": "Términos y Condiciones",
        "termsConditions3": "y el",
        "termsConditions4": "Aviso de Privacidad",
        "termsConditions5": "de Pagando Check para realizar este Pago.",
        "doPayment": "Realizar pago",
        "infoPayment": "La información de tu pago estará encriptada y segura."
    },
    "paymentStatus": {
        "success": {
            "title": "¡Pago realizado correctamente!",
            "message": "Tu pago se ha realizado con éxito. A continuación te dejamos un resumen de tu pago.",
            "phoneBusiness": "Teléfono del negocio:",
            "emailBusiness": "Correo del negocio:",
            "date": "Fecha y hora:",
            "folio": "Folio:",
            "totalPay": "Total pagado:"
        },
        "error": {
            "tryAgain": "Intentar otra vez",
            "message1": "Ponte en contacto con la organización con los siguientes datos para más información: {phone} - {email}",
            "title1": "El check link no está disponible",
            "title2": "El check link no se encuentra activado",
            "title3": "El check link todavía no esta disponible",
            "message2": "Se encontrará disponible hasta el día {date}, ponte en contacto con la organización con los siguientes datos para más información: {phone} - {email}",
            "title4": "El check link se encuentra expirado",
            "title5": "Ya se realizó un pago con este check link",
            "title6": "Ocurrió un error al procesar check link",
            "title7": "¡Algo salió mal!",
            "message3": "Lo sentimos, no hemos podido procesar su pago. Por favor inténtalo de nuevo."
        }
    }
}
