export default {
    testKey: "This is a test value",
    currentUser: {
        fullName: '',
        userPicture: ''
    },
    currentOrganization: {
        _id: '',
        name: '',
        description: null,
    }
}
