<template>
  <div class="loader-container" v-bind:class="clazz">
    <div class="">
      <div class="infinite-load m-auto" v-if="!hideloading">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
      <p v-if="loadingText">{{loadingText}}</p>
      <p v-if="extraText">{{extraText}}</p>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingBar",
  props: {
    clazz: String,
    loadingText: String,
    extraText: String,
    hideloading: { type: Boolean, default: false },
  }
};
</script>
